<template>
  <div class="dx-card">
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :toolbar="toolbar"
      :data-source="dataSource"
      @initialized="saveGridInstance"
      @init-new-row="onInitNewRow"
    >
      <dx-state-storing storage-key="mission-contactperson" />
    </dx-data-grid>
  </div>
</template>
<script lang="ts">
import {boolColumn, defaultColumn, mailColumn, phoneColumn, placeHolderColumn} from "@/utils/columns";
import {fixPopupGridLayout, toolbarRevertButton, toolbarSaveButton, useGrid} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid, DxStateStoring} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import {Column, Toolbar} from "devextreme/ui/data_grid";
import {alert} from "devextreme/ui/dialog";
import {defineComponent, onBeforeUnmount, onMounted} from "vue";

interface ContactPerson {
    id?: number,
    name: string,
    nr: string,
    selected: boolean,
    master: boolean
}

export default defineComponent({
    components: {
        DxDataGrid,
        DxStateStoring
    },
    props: {
        projectdayId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {
        const dataSource = gridDataSource({
            create: "/ansprechperson/create?projectday=" + props.projectdayId,
            read: "/ansprechperson/index",
            update: "/ansprechperson/update?projectday=" + props.projectdayId,
            destroy: "/ansprechperson/destroy"
        }, {
            onLoaded: () => {
                fixPopupGridLayout(grid.instance);
            }
        });

        const {
            grid, saveGridInstance, refreshBtn, resetBtn, allowEditing, disablePaging
        } = useGrid(dataSource);

        onMounted(() => {
            allowEditing();
            disablePaging();

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    projectday: props.projectdayId,
                    noExport: 1
                };
            });

            // workaround to check if a master flag is set if >1 selected-flags are set
            // this checks every row, i cannot abort with a "before store sync" event like ExtJS
            // so i set a flag on the first validation error and ignore all other checks
            let flagAlertActive = false;
            grid.instance.on("rowValidating", (e: { isValid: boolean }) => {
                if (!flagAlertActive) {
                    const rows = grid.instance.getVisibleRows();
                    const count_selected = rows.filter(row => row.data.selected == true).length;
                    const count_master = rows.filter(row => row.data.master == true).length;
                    if (count_selected > 1 && !count_master) {
                        e.isValid = false;
                        flagAlertActive = true;
                        alert("Bei mehreren Ansprechpartnern muss ein Haupt-Ansprechpartner gewählt werden.", "Achtung")
                        .then(() => {
                            flagAlertActive = false;
                        });
                    }
                } else {
                    e.isValid = false;
                }
            });

            // we also need to send unchanged data, so we copy them to newData
            grid.instance.on("rowUpdating", (e: { cancel: boolean, newData: ContactPerson, oldData: ContactPerson }) => {
                e.newData = {...e.oldData, ...e.newData};
            });
        });
        onBeforeUnmount(() => {
            dataSource.store().off("loading");
        });

        const onInitNewRow = (e: { data: ContactPerson }) => {
            e.data.name = e.data.nr = "";
            e.data.selected = true;
            e.data.master = false
        };

        const toolbar = {
            items: [
                "addRowButton",
                toolbarSaveButton,
                toolbarRevertButton,
                "columnChooserButton",
                resetBtn(),
                refreshBtn()
            ]
        } as Toolbar;

        const columns: Array<Column> = [
            defaultColumn({
                dataField: "name",
                allowFiltering: false,
                validationRules: [{
                    type: "required"
                }, {
                    type: "stringLength",
                    max: 45
                }]
            }),
            phoneColumn({
                dataField: "nr",
                allowFiltering: false,
                validationRules: [{
                    type: "required"
                }]
            }),
            mailColumn({
                dataField: "email",
                allowFiltering: false
            }),
            boolColumn({
                //Dieser Ansprechpartner wird für diesen Projekttag zugeteilt.
                dataField: "selected",
                caption: "Tages-Ansprechpartner",
                allowFiltering: false,
                setCellValue: (newData: ContactPerson, value: boolean, currentRowData: ContactPerson) => {
                    if (!value && currentRowData.master) {
                        newData.master = false;
                    }
                    newData.selected = value;
                }
            }),
            boolColumn({
                //Dieser Ansprechpartner erscheint an erster Stelle an diesem Tag, falls mehrere Ansprechpartner zugeteilt wurden.
                dataField: "master",
                caption: "Haupt-Ansprechpartner",
                allowFiltering: false,
                setCellValue: (newData: ContactPerson, value: boolean, currentRowData: ContactPerson) => {
                    // only one master is allowed, reset other master flags
                    if (value) {
                        grid.instance.getVisibleRows().forEach((row) => {
                            if (row.data.master && row.data.id != currentRowData.id) {
                                grid.instance.cellValue(row.rowIndex!, "master", false);
                            }
                        });

                        // also set selected
                        if (!currentRowData.selected) {
                            newData.selected = true;
                        }
                    }
                    newData.master = value;
                }
            }),
            placeHolderColumn,
            {
                type: "buttons",
showInColumnChooser: false,
                buttons: [
                    {name: "edit"},
                    {name: "delete"}
                ]
            }
        ];

        return {
            grid,
            saveGridInstance,
            dataSource,
            toolbar,
            columns,
            onInitNewRow
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
