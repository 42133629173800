<template>
  <div class="tables">
    <div class="column dx-card">
      <dx-data-grid
        ref="gridProjectDay"
        class="grid-container"
        :data-source="dataSourceProjectDay"
        :columns="columnsProjectDay"
        :column-chooser="{enabled: false}"
        :filter-row="{visible: true}"
        :paging="{enabled: false}"
        :pager="{visible: false}"
        :editing="{allowUpdating: true, mode:'cell'}"
        :selection="{mode: 'multiple', showCheckBoxesMode:'onClick', selectAllMode:'page', deferred:false}"
        :row-dragging="{group:'shared', showDragIcons:false, onAdd:onAdd, onRemove:onRemove}"
        :remote-operations="false"
        :summary="summary"
        :toolbar="toolbarProjectDay"
      >
        <template #headline>
          <h1>Für den Projekttag</h1>
        </template>
      </dx-data-grid>
    </div>
    <div class="column dx-card">
      <dx-data-grid
        ref="gridMaterial"
        class="grid-container"
        :data-source="dataSourceMaterial"
        :columns="columnsMaterial"
        :column-chooser="{enabled: false}"
        :filter-row="{visible: true}"
        :selection="{mode: 'multiple', showCheckBoxesMode:'onClick', deferred:false, allowSelectAll:false}"
        :row-dragging="{group:'shared', showDragIcons:false, dragTemplate: dragTemplate}"
        :toolbar="toolbarMaterial"
      >
        <template #headline>
          <h1>Verfügbar</h1>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>
<script lang="ts">
import {currencyColumn, currencyFormat, defaultColumn, placeHolderColumn} from "@/utils/columns";
import VEC from "@/utils/constants";
import {configureStateWithoutSelection, fixPopupGridLayout} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxButtonGroup} from "devextreme-vue/button-group";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import DataGrid, {Column, Toolbar} from "devextreme/ui/data_grid";
import {Item as dxToolbarItem} from "devextreme/ui/toolbar"
import {defineComponent, onMounted, Ref, ref} from "vue";

export interface Material {
    id: number,
    label: string,
    label_kunde: string,
    gruppe: number,
    gruppe_label: string,
    einheit: number,
    einheit_label: string,
    standardpreis: string,
    einkaufspreis: string,
    tageskontingent: number | null,
    aktiv: boolean,
    is_set: boolean | null
}

interface MaterialProjectDay {
    id?: number
    projekttag_id: number
    anzahl: number
    preis: string
    material_id: number
    material_label?: string
}

export default defineComponent({
    components: {
        DxDataGrid,
        // disable eslint error, because we need to add DxButtonGroup or it will be
        // invisible under in the Toolbar.
        DxButtonGroup  // eslint-disable-line vue/no-unused-components
    },
    props: {
        projectdayId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {
        const gridMaterial = ref<DxDataGrid | null>(null);
        const gridProjectDay = ref<DxDataGrid | null>(null);

        const {
            dataSource: dataSourceProjectDay,
            columns: columnsProjectDay,
            toolbar: toolbarProjectDay,
            onAdd, onRemove,
            summary, addMaterialsToProject
        } = useProjectDayGrid((props.projectdayId as number), gridMaterial, gridProjectDay);
        const {
            dataSource: dataSourceMaterial,
            columns: columnsMaterial,
            toolbar: toolbarMaterial,
            dragTemplate
        } = useMaterialGrid("materialprojectdayselectgrid", gridMaterial, addMaterialsToProject);

        return {
            gridProjectDay, gridMaterial,
            dataSourceProjectDay, dataSourceMaterial,
            columnsProjectDay, columnsMaterial,
            onAdd, onRemove, dragTemplate,
            toolbarProjectDay, toolbarMaterial,
            summary
        }
    }
});

const useProjectDayGrid = (projectdayId: number, gridMaterial: Ref<DxDataGrid | null>, gridProjectDay: Ref<DxDataGrid | null>) => {
    let gridProjectDayInstance: DataGrid | null = null;
    let gridMaterialInstance: DataGrid | null = null;

    const dataSource = gridDataSource({
        create: "/materialprojekttag/create",
        read: "/materialprojekttag/index",
        update: "/materialprojekttag/update",
        destroy: "/materialprojekttag/destroy"
    }, {
        onLoaded: () => {
            fixPopupGridLayout((gridProjectDayInstance as DataGrid));
            fixPopupGridLayout((gridMaterialInstance as DataGrid));
        }
    });
    dataSource.store().on("loading", (loadOptions: LoadOptions) => {
        loadOptions["userData"] = {
            ...loadOptions["userData"],
            projectday: projectdayId,
            noExport: 1
        };
    });

    onMounted(() => {
        gridProjectDayInstance = gridProjectDay.value!.instance!;
        gridMaterialInstance = gridMaterial.value!.instance!;
        configureStateWithoutSelection(gridProjectDayInstance, "materialprojectdaygrid");
    });

    const columns: Array<Column> = [
        defaultColumn({
            dataField: "material_label",
            caption: "Name",
            allowEditing: false
        }),
        currencyColumn({
            config: {
                dataField: "preis",
                caption: "Einzelpreis"
            },
            required: true,
            min: 0,
            max: 99999.99
        }),
        defaultColumn({
            dataField: "anzahl",
            dataType: "number",
            validationRules: [{
                type: "required"
            }, {
                type: "numeric"
            }],
            editorOptions: {
                format: "#", // only decimal
                min: 1,
                max: VEC.MAX_INT
            }
        }),
        placeHolderColumn
    ];

    const toolbar = {
        items: [
            {
                location: "before",
                template: "headline",
                locateInMenu: "never"
            }, {
                location: "after",
                widget: "dxButton",
                options: {
                    hint: "Material entfernen",
                    text: "Material entfernen",
                    icon: "trash",
                    onClick: () => {
                        deleteMaterial(gridProjectDayInstance!.getSelectedRowKeys());
                    }
                }
            }, {
                location: "after",
                widget: "dxButton",
                options: {
                    hint: "Refresh",
                    icon: "refresh",
                    onClick: () => {
                        gridProjectDayInstance!.refresh();
                    } // grid.instance is not available at this time, we need to use a helper function
                }
            }
        ]
    } as Toolbar;

    const deleteMaterial = (keys: Array<number>) => {
        let removes = Promise.resolve(); // initialize empty promise
        for (const key of keys) {
            removes = dataSource.store().remove(key);
        }
        removes.then(() => {
            gridProjectDayInstance!.refresh();
        });
    };

    const addMaterialsToProject = (materials: Array<Material>) => {
        let inserts = Promise.resolve(); // initialize empty promise
        for (const material of materials) {
            const exists = gridProjectDayInstance!.getVisibleRows()
            .some(row => row.data.material_id == material.id);
            if (!exists) {
                inserts = dataSource.store().insert({
                    projekttag_id: projectdayId,
                    material_id: material.id,
                    anzahl: 1,
                    preis: material.standardpreis
                } as MaterialProjectDay);
            }
        }
        // wait for batch inserts to finish
        inserts.then(() => {
            gridProjectDayInstance!.refresh();
        });
    };

    const onAdd = (e: { itemData: Material }) => {
        const materials: Array<Material> = gridMaterialInstance!.getSelectedRowsData();
        materials.push(e.itemData);
        const materials_unique = new Set(materials); // remove duplicates

        addMaterialsToProject([...materials_unique]); //pass as array again
    };

    const onRemove = (e: { itemData: MaterialProjectDay }) => {
        const keys: Array<number> = gridProjectDayInstance!.getSelectedRowKeys();
        keys.push((e.itemData.id as number));
        const keys_unique = [...new Set(keys)]; // remove duplicates
        deleteMaterial(keys_unique);
    };

    const summary = {
        totalItems: [{
            name: "PriceSummary",
            showInColumn: "preis",
            valueFormat: currencyFormat,
            summaryType: "custom"
        }],
        calculateCustomSummary: (options: any) => {
            if (options.name === "PriceSummary") {
                if (options.summaryProcess === "start") {
                    options.totalValue = 0;
                }
                if (options.summaryProcess === "calculate") {
                    options.totalValue = options.totalValue + parseFloat(options.value.preis) * options.value.anzahl;
                }
            }
        }
    };

    return {
        gridProjectDay,
        dataSource, columns,
        toolbar, onAdd, onRemove,
        summary, addMaterialsToProject
    }
};

const useMaterialGrid = (stateKey: string, gridMaterial: Ref<DxDataGrid | null>,
                         addMaterialsToProject: (materials: Array<Material>) => any) => {
    let gridMaterialInstance: DataGrid | null = null;
    const set_filter = ref("all");

    const dataSource = gridDataSource({
        read: "/material/index"
    });
    dataSource.store().on("loading", (loadOptions: LoadOptions) => {
        loadOptions["userData"] = {
            ...loadOptions["userData"],
            set_filter: set_filter.value
        };
    });

    onMounted(() => {
        gridMaterialInstance = gridMaterial.value!.instance!;
        configureStateWithoutSelection(gridMaterialInstance, stateKey);

        gridMaterialInstance.on("rowPrepared", onMaterialRowPrepared);

    });

    const columns: Array<Column> = [
        defaultColumn({
            dataField: "label",
            caption: "Name"
        }),
        currencyColumn({
            config: {
                dataField: "einkaufspreis"
            }
        }),
        currencyColumn({
            config: {
                dataField: "standardpreis"
            }
        }),
        placeHolderColumn
    ];

    const toolbar = {
        items: [
            {
                location: "before",
                template: "headline",
                locateInMenu: "never"
            }, {
                location: "after",
                locateInMenu: "never",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    hint: "Move left",
                    icon: "arrowleft",
                    onClick: () => {
                        addMaterialsToProject(gridMaterialInstance!.getSelectedRowsData());
                    } // grid.instance is not available at this time, we need to use a helper function
                }
            }, packageFilterButtonGroup(set_filter, gridMaterial), {
                location: "after",
                locateInMenu: "never",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: "Refresh",
                    hint: "Refresh",
                    icon: "refresh",
                    onClick: () => {
                        gridMaterialInstance!.refresh();
                    } // grid.instance is not available at this time, we need to use a helper function
                }
            }
        ]
    } as Toolbar;

    const dragTemplate = (e: { itemData: Material, itemElement: HTMLElement }) => {
        const keys: Array<number> = gridMaterialInstance!.getSelectedRowKeys();
        keys.push(e.itemData.id);
        const keys_unique = [...new Set(keys)]; // remove duplicates
        return `<div style="background:white;padding:8px;border:1px solid rgb(221, 221, 221)">${keys_unique.length} Einträge gewählt</div>`;
    };

    return {
        dataSource, columns,
        toolbar,
        dragTemplate,
        set_filter
    }
};

export const onMaterialRowPrepared = (e: { rowType: string, data: Material, rowElement: HTMLTableRowElement }) => {
    // paint set rows in green
    if (e.rowType !== "data")
        return;
    if (e.data.is_set) {
        e.rowElement.classList.add("material_set");
    }
};

export const packageFilterButtonGroup = (
    set_filter: Ref<string>,
    gridMaterial: Ref<DxDataGrid|null>): dxToolbarItem => {
    return {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButtonGroup",
        showText: "inMenu",
        options: {
            text: "Packagefilter",
            items: [
                {
                    text: "Packagefilter aus",
                    value: "all"
                },
                {
                    text: "nur Packages",
                    value: "only_sets"
                },
                {
                    text: "keine Packages",
                    value: "no_sets"
                }
            ],
            selectedItemKeys: ["all"],
            keyExpr: "value",
            onItemClick: (e: { itemData: { value: string } }) => {
                set_filter.value = e.itemData.value;
                gridMaterial.value!.instance!.clearSelection(); //do not trigger seperate store key load
                gridMaterial.value!.instance!.refresh();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tables {
  display: flex;

  .column:first-child {
    width: 50%;
    padding-right: 5px;
  }

  .column:last-child {
    width: 50%;
    padding-left: 5px;
  }

  @import "../themes/material.scss";
}
</style>
