//materialsetgrid
<template>
  <div>
    <dx-data-grid
      class="grid-container"
      :data-source="dataSource"
      :columns="columns"
      :toolbar="toolbar"
      :column-chooser="{enabled: false}"
      :filter-row="{visible: true}"
      :row-dragging="{group:'shared', showDragIcons:false, onAdd:onAdd, dropFeedbackMode:'push'}"
      @initialized="saveGridInstance"
    />
    <div id="materialset-footer">
      <DxNumberBox
        v-bind="formBottomItems[0]"
        v-model:value="priceBuying"
      />
      <DxNumberBox
        v-bind="formBottomItems[1]"
        v-model:value="priceStandard"
      />
      <DxButton v-bind="formBottomItems[2]" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {Material} from "@/components/mission-grid-material.vue"
import {boolColumn, currencyColumn, defaultColumn, placeHolderColumn} from "@/utils/columns";
import {configureStateWithoutSelection, fixPopupGridLayout, onPermission, useGrid} from "@/utils/grid";
import {debounce} from "@/utils/helpers";
import {gridDataSource} from "@/utils/network";
import DxButton from "devextreme-vue/button";
import {DxDataGrid} from "devextreme-vue/data-grid";
import DxNumberBox from "devextreme-vue/number-box";
import {LoadOptions} from "devextreme/data";
import DataGrid, {Column, Row, Toolbar} from "devextreme/ui/data_grid";
import {onBeforeUnmount, onMounted, ref} from "vue";

interface MaterialSet {
    parent_id: number,
    child_id: number,
    anzahl: number
}

const props = defineProps({
    parentId: {
        type: Number,
        default: null
    },
    parentRow: {
        type: Object,
        default: null
    },
    parentGrid: {
        type: Object,
        required: true
    }
});

        // set types of parent props
const parentGrid: DataGrid = (props.parentGrid as DataGrid);
const parentRow: Row = (props.parentRow as Row);

const dataSource = gridDataSource({
    create: "/materialset/create?parent_id=" + props.parentId,
    read: "/materialset/index",
    update: "/materialset/update",
    destroy: "/materialset/destroy"
}, {
    onLoaded: () => {
        fixPopupGridLayout(grid.instance);
    }
});
const {
    grid, saveGridInstance, refreshBtn, allowUpdating, allowDeleting, disablePaging, refresh
} = useGrid(dataSource);


onMounted(() => {
    onPermission(["ADM", "GFA"], allowUpdating);
    onPermission(["ADM", "GFA"], allowDeleting);
    grid.instance.option("editing")!["mode"] = "form";
    disablePaging();
    configureStateWithoutSelection(grid.instance, "materialsetgrid");

    // once click delete
    const editing = grid.instance.option("editing")!;
    editing["confirmDelete"] = false;
    grid.instance.option("editing", editing);

    dataSource.store().on("loading", (loadOptions: LoadOptions) => {
        loadOptions["userData"] = {
            ...loadOptions["userData"],
            parent_id: props.parentId,
            noExport: 1
        };
    });
});
onBeforeUnmount(() => {
    dataSource.store().off("loading");
});

const toolbar = {
    items: [
        refreshBtn()
    ]
} as Toolbar;

const columns: Array<Column> = [
    defaultColumn({
        dataField: "label",
        caption: "Name",
        allowEditing: false

    }),
    defaultColumn({
        dataField: "gruppe_label",
        caption: "Gruppe",
        allowEditing: false
    }),
    currencyColumn({
        config: {
            dataField: "einkaufspreis",
            allowEditing: false
        }
    }),
    currencyColumn({
        config: {
            dataField: "standardpreis",
            allowEditing: false
        }
    }),
    boolColumn({
        dataField: "aktiv",
        allowEditing: false
    }),
    defaultColumn({
        dataField: "anzahl",
        dataType: "number",
        alignment: "right",
        width: 90,
        validationRules: [{type: "numeric"}, {type: "required"}, {
            type: "range", min: 0.01,
            max: 9999.99
        }]
    }),
    defaultColumn({
        dataField: "einheit_label",
        caption: "Einheit",
        allowEditing: false
    }),
    placeHolderColumn,
    {
        type: "buttons",
        showInColumnChooser: false,
        buttons: [
            {name: "edit"},
            {name: "delete"}
        ]
    }
];

const priceBuying = ref(Number(parentRow.data.einkaufspreis));
const priceStandard = ref(Number(parentRow.data.standardpreis));
const formBottomItems = [{
    stylingMode: "underlined",
    width: 220,
    buttons: [{
        location: "before",
        name: "ma_kunde",
        options: {
            text: "Package Einkaufspreis:",
            focusStateEnabled: false,
            hoverStateEnabled: false,
            stylingMode: "text",
            disabled: true
        }
    }],
    onValueChanged: (e: { value: number }) => {
        parentGrid.cellValue(parentRow.rowIndex!, "einkaufspreis", e.value);
        debouncedGridSave();
    }
}, {
    stylingMode: "underlined",
    width: 220,
    buttons: [{
        location: "before",
        name: "ma_extra",
        options: {
            text: "Package Standardpreis:",
            focusStateEnabled: false,
            hoverStateEnabled: false,
            stylingMode: "text",
            disabled: true
        }
    }],
    onValueChanged: (e: { value: number }) => {
        parentGrid.cellValue(parentRow.rowIndex!, "standardpreis", e.value);
        debouncedGridSave();
    }
}, {
    text: "Preise berechnen",
    icon: "formula",
    onClick: () => {
        let sum_standardpreis = 0.0,
            sum_einkaufspreis = 0.0;
        grid.instance.getVisibleRows().forEach((row) => {
            sum_standardpreis += row.data.standardpreis * row.data.anzahl;
            sum_einkaufspreis += row.data.einkaufspreis * row.data.anzahl;
        });

        priceBuying.value = Number(sum_einkaufspreis.toFixed(2));
        priceStandard.value = Number(sum_standardpreis.toFixed(2));
    }
}] as Array<DxNumberBox | DxButton>;

const onAdd = async (e: { itemData: Material, fromComponent: DataGrid }) => {
    const materials: Array<Material> = e.fromComponent.getSelectedRowsData();
    materials.push(e.itemData);
    const materials_unique = new Set(materials); // remove duplicates

    for (const material of materials_unique) {
        const exists = grid.instance.getVisibleRows()
        .some(row => row.data.child_id == material.id);
        // don't add existing, other sets or self to list
        if (!exists && !material.is_set && material.id != props.parentId) {
            await dataSource.store().insert({
                parent_id: props.parentId,
                child_id: material.id,
                anzahl: 1
            } as MaterialSet);
        }
    }
    // await make sure refresh is called after all items are stored
    refresh();
};

// save only once after Calc button is hit
const debouncedGridSave = debounce(() => {
    parentGrid.saveEditData();
}, 100);

</script>

<style lang="scss" scoped>
  #materialset-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 5px;
    background-color: #fff;

    .dx-widget {
      margin-right: 5px
    }
  }
</style>
