import {usePiniaStore} from "@/store";
import VEC from "@/utils/constants";
import {handleErrors} from "@/utils/errors";
import {fetchBackend} from "@/utils/network";
import {LoadOptions} from "devextreme/data";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import {Properties as dxButtonOptions} from "devextreme/ui/button"
import DataGrid, {ExportingEvent, Properties as dxDataGridOptions, ToolbarItem} from "devextreme/ui/data_grid";
import {confirm} from "devextreme/ui/dialog";
import {Properties as dxSelectBoxOptions} from "devextreme/ui/select_box"
import {Item as dxToolbarItem, ShowTextMode} from "devextreme/ui/toolbar"
import {Workbook} from "exceljs";
import {exportDataGrid} from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import hotkeys from "hotkeys-js";
import {onBeforeUnmount, onMounted, reactive, ref, Ref, watch} from "vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGrid = (dataSource: DataSource) => {
    const store = usePiniaStore();

    let regionWatcher = () => {
        // define as empty function as i gets called on beforeDestroy() anyway
    };
    let firmWatcher = () => {
        // define as empty function as i gets called on beforeDestroy() anyway
    };
    let yearWatcher = () => {
        // define as empty function as i gets called on beforeDestroy() anyway
    };

    const grid = reactive({
        noRegion: true,
        noFirm: true,
        noYear: true,
        instance: undefined as unknown as DataGrid
    });

    onMounted(() => {
        dataSource.store().on("loading", (loadOptions: LoadOptions) => {
            const activeRegion = store.filter.region;
            const activeFirm = store.filter.firm;
            const activeYear = store.filter.year;
            loadOptions["userData"] = {
                ...loadOptions["userData"],
                ...(activeRegion != null && !grid.noRegion && {region: activeRegion}),
                ...(activeFirm != null && !grid.noFirm && {firm: activeFirm}),
                ...(activeYear != null && !grid.noYear && {year: activeYear})
            };
        });

        if (!grid.noRegion) {
            regionWatcher = watch(() => store.filter.region, () => { refresh() });
        }
        if (!grid.noFirm) {
            firmWatcher = watch(() => store.filter.firm, () => { refresh() });
        }
        if (!grid.noYear) {
            yearWatcher = watch(() => store.filter.year, () => { refresh() });
        }

        // row double click start editor
        // remember clicked cell and focus according form field in the editor form
        let clickedFieldToFocus: string | undefined;
        grid.instance.on("cellDblClick", (e: any) => {
            if (e.rowType == "data") {
                clickedFieldToFocus = e.column.dataField;
                e.component.editRow(e.rowIndex);
            }

        });
        grid.instance.on("editorPrepared", (e: any) => {
            if (e.parentType == "dataRow" && e.dataField == clickedFieldToFocus) {
                clickedFieldToFocus = undefined; // reset last clicked field
                if (e.editorOptions.inputAttr.id) {
                    setTimeout(function () {
                        const element = document.getElementById(e.id);
                        if (element) element.focus();
                    }, 300); // 300 seems to work with form, popup edit needs more
                }
            }
        });

        // hotkeys
        hotkeys("ctrl+s, command+s", () => {
            grid.instance.saveEditData();
            return false; //prevent browser default action
        });
        hotkeys("ctrl+z, command+z", () => {
            grid.instance.cancelEditData();
            return false; //prevent browser default action
        });

    });
    onBeforeUnmount(() => {
        dataSource.store().off("loading");
        regionWatcher();
        firmWatcher();
        yearWatcher();
        hotkeys.unbind("ctrl+s, command+s");
        hotkeys.unbind("ctrl+z, command+z");
    });

    // needed helper function, because grid.instance is not available on Datagrid.toolbar
    const refresh = () => {
        grid.instance.refresh();
    };

    // called by initialized event on grid
    const saveGridInstance = (e: { component: DataGrid }) => {
        grid.instance = e.component;
    };

    const allowFiltering = () => {
        grid.instance.option("filterRow")!["visible"] = true;
        grid.instance.option("filterPanel")!["visible"] = true;
    };

    const allowEditing = () => {
        const editing = grid.instance.option("editing")!;
        editing["allowUpdating"] = editing["allowAdding"] = editing["allowDeleting"] = true;
        grid.instance.option("editing", editing);
    };

    const disableEditing = () => {
        const editing = grid.instance.option("editing")!;
        editing["allowUpdating"] = editing["allowAdding"] = editing["allowDeleting"] = false;
        grid.instance.option("editing", editing);
    };

    const allowUpdating = () => {
        const editing = grid.instance.option("editing")!;
        editing["allowUpdating"] = true;
        grid.instance.option("editing", editing);
    };

    const allowDeleting = () => {
        const editing = grid.instance.option("editing")!;
        editing["allowDeleting"] = true;
        grid.instance.option("editing", editing);
    };

    const allowExporting = () => {
        const exporting = grid.instance.option("export")!;
        exporting["enabled"] = true;
        grid.instance.option("export", exporting);
        grid.instance.on("exporting", _onExporting);
    };

    const disablePaging = () => {
        grid.instance.option("paging")!["enabled"] = false;
        grid.instance.option("pager")!["visible"] = false;
    };

    const disableColumnChooser = () => {
        const columnChooser = grid.instance.option("columnChooser")!;
        columnChooser["enabled"] = false;
        grid.instance.option("columnChooser", columnChooser);
    };

    const disableState = () => {
        const stateStoring = grid.instance.option("stateStoring")!;
        stateStoring["enabled"] = false;
        grid.instance.option("stateStoring", stateStoring);
    };

    const refreshBtn = (showText: ShowTextMode = "inMenu") => {
        const btn = toolbarRefreshButton;
        btn.options.onClick = refresh; // grid.instance is not available at this time, we need to use a helper function
        btn.showText = showText;
        return btn;
    };

    const resetBtn = (otherStateKeys?: Array<string>) => {
        return {
            location: "after",
            locateInMenu: "auto",
            showText: "inMenu",
            widget: "dxButton",
            options: {
                hint: "Einstellungen dieser Ansicht zurücksetzen",
                icon: "clear",
                text: "Einstellungen zurücksetzen",
                onClick: () => {
                    const key = grid.instance.option("stateStoring")!.storageKey;
                    const result = confirm("Spalten, Abstände, Sortierung, etc. dieser Ansicht wird zurückgesetzt und die Seite wird neu geladen!", "Achtung");
                    result.then((dialogResult) => {
                        if (dialogResult) {
                            localStorage.removeItem(key || "");
                            if (otherStateKeys && otherStateKeys.length > 0) {
                                otherStateKeys.forEach(key => localStorage.removeItem(key));
                            }
                            window.location.reload();
                        }
                    });
                }
            } as dxButtonOptions
        } as dxToolbarItem;
    };

    return {
        grid,
        saveGridInstance,
        refresh,
        refreshBtn,
        resetBtn,
        allowEditing, allowUpdating, allowDeleting, allowExporting, allowFiltering,
        disableEditing,
        disablePaging,
        disableColumnChooser,
        disableState
    }
};


export const allowFiltering: dxDataGridOptions = {
    filterRow: {visible: true},
    filterPanel: {visible: true}
};

const _onExporting = (e: ExportingEvent): void => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const filename = e.component.option("stateStoring")!["storageKey"] || "DataGrid";
    exportDataGrid({
        component: e.component,
        worksheet: worksheet
    }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), filename + '.xlsx');
            });
    });
};

export const allowExporting: dxDataGridOptions = {
    export: {enabled: true},
    onExporting: _onExporting
};

export const allowUpdating: dxDataGridOptions = {
    editing: {allowUpdating: true}
};

export const disableGridState = {
    stateStoring: {enabled: false}
};

export const disableGridPaging = {
    paging: {enabled: false},
    pager: {visible: false}
};

export const disableGridColumnChooser = {
    columnChooser: {enabled: false}
};

export const useGridBooleanFilter = ({defaultValue = true, key = "onlyactive", name = "aktive", location = "after", dataSource, refresh}: { defaultValue?: boolean, key?: string, name?: string, location?: "after" | "before" | "center", dataSource: DataSource, refresh: any }): { filter: Ref<boolean>, buttonFilter: dxToolbarItem } => {
    const filter = ref(defaultValue);

    onMounted(() => {
        dataSource.store().on("loading", (loadOptions: LoadOptions) => {
            loadOptions["userData"] = {
                ...loadOptions["userData"],
                [key]: filter.value
            };
        });
    });
    onBeforeUnmount(() => {
        dataSource.store().off("loading");
    });

    const toggleShowOnlyActive = (e: { value: boolean }) => {
        filter.value = e.value;
        refresh();
    };

    const buttonFilter: dxToolbarItem = {
        location: location,
        locateInMenu: "auto",
        widget: "dxCheckBox",
        options: {
            text: name,
            value: filter.value,
            onValueChanged: toggleShowOnlyActive
        }
    };

    return {
        filter,
        buttonFilter
    }
};

export const hasPermission = (roles: Array<string>): boolean => {
    const store = usePiniaStore();
    return roles.includes(store.user.role ? store.user.role : "")
};

export const onPermission = (roles: Array<string>, callback: () => void): void => {
    if (hasPermission(roles)) {
        callback();
    }
};

// custom state handling which removes selected rows from state
// setting a "key" on the store and using deferred == false ends with extra store requests
// if selected ids are not found, so we disable selection state
export const configureStateWithoutSelection = (gridInstance: DataGrid, stateKey: string): void => {
    const stateStoring = gridInstance.option("stateStoring")!;
    stateStoring["storageKey"] = stateKey;
    stateStoring["type"] = "custom";
    stateStoring["customLoad"] = () => {
        const state = JSON.parse(localStorage.getItem(stateKey) || "{}");
        return Promise.resolve(state);
    };
    stateStoring["customSave"] = (state: any) => {
        state.selectedRowKeys = undefined; // <-- selectedRowKeys is cleared
        localStorage.setItem(stateKey, JSON.stringify(state));
    };
    gridInstance.option("stateStoring", stateStoring);
};

/**
 * @deprecated use toolbarSaveButton and toolbarRevertButton instead
 */
export const showToolbarEditButtonsText = (items: any[]): void => {
    items.forEach(function (item) {
        if (item.name == "saveButton") {
            item.showText = "always";
            item.options.text = "Speichern";
        } else if (item.name == "revertButton") {
            item.showText = "always";
            item.options.text = "Reset";
        }
    });
};

export const toolbarSaveButton: ToolbarItem = {
    name: "saveButton",
    showText: "always",
    locateInMenu: "never",
    options: {
        text: "Speichern"
    }
};

export const toolbarRevertButton: ToolbarItem = {
    name: "revertButton",
    showText: "always",
    locateInMenu: "never",
    options: {
        text: "Reset"
    }
};

export const toolbarRefreshButton: ToolbarItem = {
    location: "after",
    widget: "dxButton",
    locateInMenu: "auto",
    showText: "inMenu",
    options: {
        text: "Refresh",
        hint: "Refresh",
        icon: "refresh"
    } as dxButtonOptions
};


// fix wrong dimensions on grid when using columnHidingEnabled within a popup
export const fixPopupGridLayout = (datagrid: DataGrid): void => {
    setTimeout(() => {
        datagrid.repaint();
    }, 150);
}

// load installation specific checkbox labels from settings
export const loadPersonalCheckboxLabels = (grid_instance: DataGrid): void => {
    fetchBackend("/index/settingsselect", {
        method: "POST",
        body: new URLSearchParams({
            keys: JSON.stringify([
                "personalCheckbox1", "personalCheckbox2", "personalCheckbox3",
                "personalCheckbox4", "personalCheckbox5", "personalCheckbox6",
            ])
        })
    }).then(handleErrors).then((result) => {
        grid_instance.columnOption("cb1", "caption", result.personalCheckbox1);
        grid_instance.columnOption("cb2", "caption", result.personalCheckbox2);
        grid_instance.columnOption("cb3", "caption", result.personalCheckbox3);
        grid_instance.columnOption("cb4", "caption", result.personalCheckbox4);
        grid_instance.columnOption("cb5", "caption", result.personalCheckbox5);
        grid_instance.columnOption("cb6", "caption", result.personalCheckbox6);
    });
}

const statusStore = new ArrayStore({
    data: [
        {id: VEC.STATE_PLANED, name: "geplant"},
        {id: VEC.STATE_DISPOSED, name: "eingeteilt"},
        {id: VEC.STATE_INFORMED, name: "informiert"},
        {id: VEC.STATE_CLOSED, name: "abgeschlossen"},
        {id: VEC.STATE_LOCKED, name: "gesperrt"}
    ],
    key: "id"
});


export const selectBoxState = (config?: dxSelectBoxOptions): dxSelectBoxOptions => {
    return {
        ...lookupState,
        ...config
    }
};

export const lookupState = {
    dataSource: {
        store: statusStore
    },
    valueExpr: "id",
    displayExpr: "name"
};
