<template>
  <div>
    <dx-select-box
      :show-clear-button="true"
      :placeholder="placeholder"
      value-expr="id"
      display-expr="label"
      :data-source="dataSource"
      :value="activeValue"
      @value-changed="onValueChanged"
    />
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import ArrayStore from "devextreme/data/array_store";
import {firmStore, regionStore} from "@/utils/network";
import {usePiniaStore} from "@/store";

const selectBoxDataSource = (name) => {
    if (name === "year") {
        return new ArrayStore({
            data: (() => {
                let currentYear = new Date().getFullYear();
                let startYear = parseInt(import.meta.env.VITE_VEC_STARTYEAR);
                return Array.from(Array(currentYear - startYear + 1), (_, x) => ({
                    id: startYear + x,
                    label: startYear + x
                })).reverse();
            })(),
        });

    } else if (name === "region") {
        return regionStore;
    } else if (name === "firm") {
        return firmStore;
    }
};

export default {
    components: {
        DxSelectBox
    },
    props: {
        name: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            dataSource: selectBoxDataSource(this.name),
            store: usePiniaStore()
        }
    },
    computed: {
        activeValue() {
            return this.store.filter[this.name];
        }
    },
    methods: {
        onValueChanged(e) {
            this.store.setFilterValue(this.name, e.value);
        }
    }
}
</script>

<style scoped>

</style>
