<template>
  <div class="user-panel">
    <div class="user-info">
      <div class="image-container">
        <div class="user-image dx-icon-user" />
      </div>
      <div class="user-name">
        {{ userName }}
      </div>
      <div class="user-role">
        ({{ userRole }})
      </div>
    </div>

    <dx-context-menu
      v-if="menuMode === 'context'"
      target=".user-button"
      :items="menuItems"
      :width="170"
      :position="menuPositionConfig"
      show-event="dxclick"
      css-class="user-menu"
    />

    <dx-list
      v-if="menuMode === 'list'"
      class="dx-toolbar-menu-action"
      :items="menuItems"
    />
  </div>
</template>

<script>
import DxContextMenu from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";
import {usePiniaStore} from "@/store";

export default {
    components: {
        DxContextMenu,
        DxList
    },
    props: {
        /* eslint-disable vue/require-default-prop */
        menuMode: String,
        menuItems: Array
        /* eslint-enable vue/require-default-prop */
    },
    data() {
        return {
            store: usePiniaStore(),
            menuPositionConfig: {
                my: "top center",
                at: "bottom center"
            }
        };
    },
    computed: {
        userName() {
            return this.store.user.name
        },
        userRole() {
            return this.store.user.role
        }
    }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.user-info {
  display: inline-flex;
  align-items: center;
  min-width: 170px;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 0 4px;
    height: 25px;
    width: 25px;

    .user-image {
      width: 100%;
      height: 100%;
      /*background: url("https://www.viennaeventcrew.at/wp-content/uploads/2015/12/VEC-TEAM-400x400-stefan.png") no-repeat #fff;*/
      background-size: cover;
      padding-left:4px;
      padding-top:4px;

      .screen-medium &, .screen-large & {
        padding-left:0;
      }
    }
  }

  .user-name {
    font-size: 14px;
    color: $base-text-color;
    overflow: hidden;
    padding: 10px 0 10px 0;
    max-width: 200px;
    text-overflow: clip;
    white-space: nowrap;
  }

  .user-role {
    font-size: 14px;
    color: lighten( $base-text-color, 50% );
    margin: 0 4px;

  }
}

.user-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 16px;
  }

  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }

  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }

  .dx-menu-item .dx-menu-item-content {
    padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
