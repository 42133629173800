<template>
  <div>
    <h1 v-if="showTitle">
      {{ projectTitle }}
    </h1>
    <DxDataGrid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      :toolbar="toolbar"
      @initialized="saveGridInstance"
    >
      <template #stateCellColor="{ data }">
        <state-svg :state-id="data.value" />
      </template>
      <template #stateSelectBoxItem="{ data }">
        <div>
          <!-- id/name for dropdown, icon/text for button -->
          <state-svg :state-id="data.id || data.icon" />&nbsp;{{ data.name || data.text }}
        </div>
      </template>
      <template #lookup="data">
        <DxLookup
          v-bind="data.data.options"
        />
      </template>
      <template #crewleaddata_toggle="data">
        <DxButton
          v-bind="data.data.options"
          :type="showCrewleadData ? 'success' : 'normal'"
        />
      </template>
      <template #crewleaddata_apply="data">
        <DxButton
          v-bind="data.data.options"
          :visible="showCrewleadData"
        />
      </template>
    </DxDataGrid>
    <DxToolbar
      render-as="bottomToolbar"
      :items="toolbarBottomItems"
      :element-attr="{class:'bottom-toolbar'}"
    />
    <div id="mission-footer">
      <DxNumberBox v-bind="formBottomItems[0]" />
      <DxNumberBox v-bind="formBottomItems[1]" />
      <DxTextArea v-bind="formBottomItems[2]" />
    </div>
    <DxLoadPanel
      :visible="showLoadingIndicator"
    />
  </div>
</template>
<script setup lang="ts">
import StateSvg from "@/components/state-svg.vue";
import {usePiniaStore} from "@/store";
import {
    boolColumn,
    checkProbe,
    checkProfi,
    currencyColumn,
    defaultColumn,
    hourColumn,
    hourFormat,
    placeHolderColumn,
    stateColumn,
    timeColumn
} from "@/utils/columns";
import VEC from "@/utils/constants";
import {handleErrors, handleMinimalErrors} from "@/utils/errors";
import {
    configureStateWithoutSelection,
    hasPermission,
    onPermission,
    toolbarRevertButton,
    toolbarSaveButton,
    useGrid
} from "@/utils/grid";
import {createCustomPopup, downloadFile, missionTitle, my_notify} from "@/utils/helpers";
import {buildQueryString, fetchBackend, gridDataSource, personalComboStore, tarifComboStore} from "@/utils/network";
import {DxButton} from "devextreme-vue/button";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {DxLoadPanel} from "devextreme-vue/load-panel";
import {DxLookup} from "devextreme-vue/lookup";
import DxNumberBox from "devextreme-vue/number-box";
import {DxTextArea} from "devextreme-vue/text-area";
import {DxToolbar} from "devextreme-vue/toolbar";
import devices from "devextreme/core/devices";
import {LoadOptions} from "devextreme/data";
import {formatDate, formatNumber} from "devextreme/localization";
import {Properties as dxButtonOptions} from "devextreme/ui/button"
import DataGrid, {Column, EditorPreparingEvent, Toolbar} from "devextreme/ui/data_grid";
import {Properties as dxDateBoxOptions} from "devextreme/ui/date_box"
import {alert, confirm, custom} from "devextreme/ui/dialog";
import {Properties as dxNumberBoxOptions} from "devextreme/ui/number_box"
import {Item as dxToolbarItem} from "devextreme/ui/toolbar"
import Popup from "devextreme/ui/popup"
import hotkeys from "hotkeys-js";
import {onBeforeUnmount, onMounted, reactive, ref, Ref, watch} from "vue";

interface Mission {
    id: number,
    projekttag_id: number,
    status: number,
    personal_id: number | null,
    personal_name: string,
    tarif_id: number,
    tarif_label: string,
    checkin: string | Date,
    checkout: string | Date,
    anfahrt: number,
    pause: number, // unbezahlte Pause
    pause2: number, // bezahlte Pause
    km_geld: number,
    km_geld_kunde: number,
    pauschalen: number,
    probe: boolean,
    profi: boolean,
    crewleader: boolean,
    stunden: number,
    stunden_min: number,
    stundensatz: number,
    stundensatz_anfahrt: number,
    brutto: number,
    personal_profi_default: boolean
}

interface Project {
    id: number,
    projekttag_id: number,
    tag: string,
    name: string,
    ort: string,
    kunde_id: number,
    kunde_name: string,
    kunde_adresse: string,
    kunde_plz: string,
    kunde_ort: string,
}

const emptyProject = (): Project => ({
    id: 0,
    projekttag_id: 0,
    tag: "",
    name: "",
    ort: "",
    kunde_id: 0,
    kunde_name: "",
    kunde_adresse: "",
    kunde_plz: "",
    kunde_ort: ""
});

// interface Tarif {
//     aktiv: boolean,
//     id: number,
//     label: string,
//     label_kunde: string,
//     label_kurz: string,
//     stundensatz: number
//     stundensatz_selbstständig: number
// }
//
// interface PersonalCombo {
//     id: number,
//     value: string,
//     probe: boolean,
//     profi: boolean,
// }

const props = defineProps({
    projectdayId: {
        type: String,
        default: null
    },
    popupInstance: {
        type: Object,
        default: null
    },
    showTitle: {
        type: Boolean,
        default: false
    }
});

// mission-grid gets mounted before popupInstance is set, so we need to watch it if
// we want to add a popup hiding event to ask for unsaved changes
watch(() => props.popupInstance, () => {
    (props.popupInstance as Popup).on("hiding", (e: any) => {
        if (grid.instance.hasEditData()) {
            e.cancel = true; // abort hiding
            confirm("Es gibt ungespeicherte Änderungen, wirklich schließen?", "Achtung")
            .then((result) => {
                if (result) {
                    grid.instance.cancelEditData();
                    (props.popupInstance as Popup).hide();
                }
            });
        }
    });
});

const projectTitle = ref("");
const defaultTarifLabel = ref("");

// loaded via onDataLoaded
const project: Project = emptyProject();

const onDataLoaded = async (result: any) => {
    // project = result.project would overwrite the object and make pass by reference useless
    Object.assign(project, result.project);

    projectTitle.value = missionTitle(project.name, project.tag, project.kunde_name, project.ort);
    if(props.showTitle){ //change browser title in fullscreen mode
        const store = usePiniaStore();
        document.title = projectTitle.value + " | " + store.config.title;
    }

    // replace button options (replacing single prop does not trigger reactivness)
    buttonContactPerson.options = {...buttonContactPerson.options, text: result.contactperson};

    //bottom info
    formBottomItems[0].value = result.project.personal;
    formBottomItems[1].value = result.project.personal_zusaetzlich;
    formBottomItems[2].value = result.project.memo;

    // load default tarif label here as "onInitNewRow" does not allow async call
    const defaultTarif = await tarifComboStore.byKey(VEC.DEFAULT_TARIF)
    defaultTarifLabel.value = defaultTarif.label;
};

const dataSource = gridDataSource({
    create: "/einsatz/create?projectday=" + props.projectdayId,
    read: "/einsatz/index",
    update: "/einsatz/update",
    destroy: "/einsatz/destroy"
}, {onLoaded: onDataLoaded});

const {
    grid, saveGridInstance, refreshBtn, resetBtn,
    allowEditing, disablePaging
} = useGrid(dataSource);

const usePermissionHandling = (grid: { instance: DataGrid }) => {

    let kmAlertActive = false;

    onMounted(() => {
        grid.instance.on({
            "editingStart": onEditingStart,
            "rowInserting": onRowInserting,
            "rowUpdating": onRowUpdating,
            "rowRemoving": onRowRemoving
        });
    });

    const checkEditAllowed = (data: Mission, field?: string | undefined) => {
        if (data.status == VEC.STATE_LOCKED && !hasPermission(["ADM", "GFA", "BOF"])) {
            console.debug(`state is locked, editing ${field} is not allowed`);
            return false;
        } else if (field === "pauschalen" && !hasPermission(["ADM", "GFA", "BOF", "HDI"])) {
            console.debug(`pauschalen edit is not allowed`);
            return false;
        } else {
            return true;
        }
    };

    // when user hits a cell to edit, or a checkbox cell gets rendered (which has to check for edit in render)
    const onEditingStart = (e: { cancel: boolean, column: Column, data: Mission }) => {
        if(e.column && e.column.dataField){ // this if suppresses a js error when datafield is not set
            checkEditAllowed(e.data, e.column.dataField) || (e.cancel = true);
        } else {
            e.cancel = true;
        }
    };

    // when the user hits the save button for every inserted row
    const onRowInserting = (e: { cancel: boolean, data: Mission }) => {
        // destructure function to existing object e: https://stackoverflow.com/a/40624564/156448
        Object.assign(e, checksBeforeSave(e.data, e.data))  //oldData=newData here
        // or use ({newData: e.newData, cancel: e.cancel} = checksBeforeSave(e.newData, e.oldData));
    };

    // when the user hits the save button (not on field change unfortunately)
    const onRowUpdating = (e: { cancel: boolean, newData: Mission, oldData: Mission }) => {
        // destructure function to existing object e: https://stackoverflow.com/a/40624564/156448
        Object.assign(e, checksBeforeSave(e.newData, e.oldData))
        // or use ({newData: e.newData, cancel: e.cancel} = checksBeforeSave(e.newData, e.oldData));
    };

    // when the user hits the save button (for every deleted row)
    const onRowRemoving = (e: { cancel: boolean, data: Mission, key: number }) => {
        // only check id for permissions, as we do not want the pauschalen check to trigger
        const editAllowed = checkEditAllowed(e.data, "id");
        if (!editAllowed) {
            e.cancel = true;
            my_notify("warning", `Löschen von Zeile ${e.data.personal_name || ""} nicht erlaubt!`);
        }
    };

    const checksBeforeSave = (newData:Mission, oldData:Mission) => {
        let cancel = false;

        // Array.every() breaks loop on false return value
        const editAllowed = Object.keys(newData).every((field) => {
            return checkEditAllowed(oldData, field);
        });
        if (!editAllowed) {
            cancel = true;
            my_notify("warning", `Editieren von Zeile ${oldData.personal_name || ""} nicht erlaubt!`);
        }

        // show km alert only once
        if (!kmAlertActive) {
            const km_geld = ("km_geld" in newData) ? newData.km_geld : oldData.km_geld;
            const km_geld_kunde = ("km_geld_kunde" in newData) ? newData.km_geld_kunde : oldData.km_geld_kunde;
            if (km_geld_kunde < km_geld) {
                kmAlertActive = true;
                alert("Kilometergeld ist größer als das dem Kunden verrechnete Kilometergeld.", "Bitte KM Geld kontrollieren")
                .then(() => {
                    kmAlertActive = false;
                });
            }
        }

        // convert time columns to string before backend save
        if (newData.checkin && newData.checkin instanceof Date) {
            // checkin is a string from backend, gets converted to Date in the grid column, but needs to send string
            // back to backend
            // shut up typescript on type mismatch here by type cheating
            newData.checkin = formatDate((newData.checkin as any), "HH:mm");
        }
        if (newData.checkout && newData.checkout instanceof Date) {
            newData.checkout = formatDate((newData.checkout as any), "HH:mm");
        }

        return {
            newData,
            cancel
        };
    };

    return {
        checkEditAllowed
    };

};

const useCRUD = (grid: { instance: DataGrid },
                 checkEditAllowed: (data: Mission, field?: string | undefined) => boolean,
                 project: Project,
                 defaultTarifLabel: Ref<string>) => {

    onMounted(() => {
        grid.instance.on({
            "initNewRow": onInitNewRow,
            "contextMenuPreparing": onContextMenuPreparing,
            "editorPreparing": onEditorPreparing
        });

        // hotkeys
        hotkeys("1, 2, 3, 4, 5", (event, handler) => {
            switch (handler.key) {
                case "1":
                    changeSelectedRowCell("status", VEC.STATE_PLANED);
                    break;
                case "2":
                    changeSelectedRowCell("status", VEC.STATE_DISPOSED);
                    break;
                case "3":
                    changeSelectedRowCell("status", VEC.STATE_INFORMED);
                    break;
                case "4":
                    changeSelectedRowCell("status", VEC.STATE_CLOSED);
                    break;
                case "5":
                    changeSelectedRowCell("status", VEC.STATE_LOCKED);
                    break;
            }
        });
    });

    onBeforeUnmount(() => {
        hotkeys.unbind("1, 2, 3, 4, 5");
    });

    const onInitNewRow = (e: { data: Mission }) => {
        const data = e.data;
        data.status = VEC.STATE_PLANED;
        data.tarif_id = VEC.DEFAULT_TARIF;
        // "onInitNewRow" does not allow async call, so we load defaultTarifLabel in "onDataLoaded"
        data.tarif_label = defaultTarifLabel.value
        data.anfahrt = data.pause = 0; //trigger needs this
        data.pause2 = data.km_geld = data.km_geld_kunde = data.pauschalen = 0;
    };

    const onContextMenuPreparing = (e: {
        target: string, items: Array<Record<string, unknown>>,
        row: { data: Mission }, rowIndex: number, column: Column
    }) => {
        if (e.target == "content") {
            // e.items can be undefined
            if (!e.items) e.items = [];

            e.items.push({
                disabled: true,
                template: `<b>${e.row.data.personal_name || "(kein Name)"}</b>`
            }, {
                beginGroup: true,
                id: 0,
                name: "geplant",
                template: "stateSelectBoxItem",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "status", 0);
                }
            }, {
                id: 1,
                name: "eingeteilt",
                template: "stateSelectBoxItem",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "status", 1);
                }
            }, {
                id: 2,
                name: "informiert",
                template: "stateSelectBoxItem",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "status", 2);
                }
            }, {
                id: 3,
                name: "abgeschlossen",
                template: "stateSelectBoxItem",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "status", 3);
                }
            }, {
                id: 4,
                name: "gesperrt",
                template: "stateSelectBoxItem",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "status", 4);
                }
            }, {
                beginGroup: true,
                text: "Toggle Noob",
                icon: "far fa-smile",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "probe", !e.row.data.probe);
                }
            }, {
                text: "Toggle Profi",
                icon: "fas fa-user-graduate",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "profi", !e.row.data.profi);
                }
            }, {
                beginGroup: true,
                text: "Toggle Crewleitung",
                icon: "favorites",
                onItemClick: () => {
                    grid.instance.cellValue(e.rowIndex, "crewleader", !e.row.data.crewleader);
                }
            }, {
                beginGroup: true,
                text: "Einsatz löschen",
                icon: "fas fa-people-carry",
                onItemClick: () => {
                    grid.instance.deleteRow(e.rowIndex);
                }
            });
        }
    };

    const onEditorPreparing = (e: EditorPreparingEvent) => {

        if (e.parentType == "dataRow") {
            // lookup fields special treatment
            if (['personal_id','tarif_id','status'].includes(e.dataField!)) {

                const minWidth = e.dataField == "personal_id" ? 150 : 110;

                e.editorOptions.searchEnabled = e.dataField != "status"; // no text input for status
                e.editorOptions.wrapItemText = true; // for future longer entries

                //open lookup once focused
                e.editorOptions.onFocusIn = (e: any) => {
                    e.component.open()
                };

                // minimal item width
                e.editorOptions.onOpened = (e: any) => {
                    e.component._popup.option("width", minWidth);
                };

                // needed to retouch displayValue of lookup columns, see
                // https://supportcenter.devexpress.com/ticket/details/t1038067/datagrid-the-calculatedisplayvalue-method-is-not-called-for-certain-rows-after-changing
                e.editorOptions.onValueChanged = (args: any) => {
                    e.setValue(args.value);
                }
            }
        }
    };

    const setCellValue = async (field: keyof Mission, newData: Mission, value: string | boolean, currentRowData: Mission) => {

        // set new value, if allowed
        // this catches batch edits via header toolbar (user cell edits are catched via onEditingStart
        if (checkEditAllowed(currentRowData, field)) {
            // surpress: ts2322 type 'string' is not assignable to type 'never'
            // see https://github.com/microsoft/TypeScript/issues/31663#issuecomment-519563197
            (newData[field] as any) = value;
        } else {
            return;
        }

        const resetStatusOnCheckinCheckoutChange = () => {
            const status = "status" in newData ? newData.status : currentRowData.status;
            const checkin = "checkin" in newData ? newData.checkin : currentRowData.checkin;
            const checkout = "checkout" in newData ? newData.checkout : currentRowData.checkout;
            if (status >= VEC.STATE_INFORMED && (!checkin || !checkout)) {
                console.debug("setting status back, checkin or checkout is missing");
                newData.status = VEC.STATE_DISPOSED;
            }
        };
        const resetStatusOnPersonalChange = () => {
            const status = "status" in newData ? newData.status : currentRowData.status;
            const personal_id = "personal_id" in newData ? newData.personal_id : currentRowData.personal_id;
            if (status != VEC.STATE_PLANED && !personal_id) {
                console.debug("setting status back, there is no personal_id set yet");
                newData.status = VEC.STATE_PLANED;
            }
        };
        const checkHoliday = async () => {
            // use async/await here, so we can change newData.status after server call
            const status = "status" in newData ? newData.status : currentRowData.status;
            const personal_id = "personal_id" in newData ? newData.personal_id : currentRowData.personal_id;

            if (
                // status change from red to any other state
                (field == "status" && personal_id && status >= VEC.STATE_DISPOSED && currentRowData.status == VEC.STATE_PLANED) ||
                // or personal_id change to any state except red
                (field == "personal_id" && personal_id && status >= VEC.STATE_DISPOSED)
            ) {
                const response = await fetchBackend(`/einsatz/checkholiday`, {
                    method: "POST",
                    body: JSON.stringify({
                        personal_id: personal_id,
                        date: project.tag
                    })
                });
                const result = await handleMinimalErrors(response);
                if (result) {
                    let message, holiday_type;
                    if(result.holiday){
                        message = `${result.personal_name} ist auf Urlaub.`;
                        holiday_type = "Urlaub";
                    } else if (result.sick_leave) {
                        message = `${result.personal_name} ist im Krankenstand.`;
                        holiday_type = "Krankenstand";
                    } else {
                        message = `${result.personal_name} ist nicht verfügbar.`;
                        holiday_type = "Abwesenheit";
                    }
                    message += ` Trotzdem einteilen?<br/> (${holiday_type} wird dabei gelöscht)`;
                    const dialogResult = await confirm(message, "Achtung");
                    // db trigger removes holiday on mission save, so wo have no work here if dialogResult is true
                    if (!dialogResult) {
                        newData.status = VEC.STATE_PLANED;
                    }
                }
            }
        };

        if (field == "status") {
            resetStatusOnCheckinCheckoutChange();
            resetStatusOnPersonalChange();
            await checkHoliday();

        } else if (field == "checkin" || field == "checkout") {
            if (currentRowData.status == VEC.STATE_INFORMED) {
                console.debug("time changed, setting status back");
                newData.status = VEC.STATE_DISPOSED;
            }
            resetStatusOnCheckinCheckoutChange();
        } else if (field == "personal_id") {
            resetStatusOnPersonalChange();
            await checkHoliday();
            if (!value) {
                //reset all if empty
                newData.personal_profi_default = newData.probe = newData.profi = false
                newData.personal_name = ""; //reset name if set by header combo (multi dispose)
            } else {
                const comboData = await personalComboStore.byKey(value);
                newData.personal_name = comboData.value; // set personal_name if set by header combo (multi dispose)

                //preselect boolean fields coming with the record
                //default value for profi is checked later with this.setProfiFlag
                if (comboData.probe != currentRowData.probe) {
                    newData.probe = comboData.probe;
                }
                if (comboData.profi != currentRowData.personal_profi_default) {
                    newData.personal_profi_default = comboData.profi;
                }

                //check, if this staff is disposed on other projects on the same day (do not warn on same project an same day)
                //returns projectday ids, so the staff can be deleted there
                const params = {
                    personal_id: newData.personal_id,
                    projekttag_id: project.projekttag_id
                };
                fetchBackend(`/einsatz/checkmultiplemission?${buildQueryString(params)}`)
                .then(handleMinimalErrors)
                .then((result) => {
                    if (result.length) {
                        loopMultipleMissions(result, 0);
                    }
                });

                //set default profi flag, if allowed
                setProfiFlag({
                    personal_id: newData.personal_id,
                    tarif_id: currentRowData.tarif_id,
                    newData: newData,
                    currentRowData: currentRowData,
                    defaultFlag: comboData.profi
                });
            }
        } else if (field == "tarif_id") {
            const comboData = await tarifComboStore.byKey(value);
            newData.tarif_label = comboData.label; // set tarif_label if set by header combo (multi dispose)
            setProfiFlag({
                personal_id: currentRowData.personal_id,
                tarif_id: newData.tarif_id,
                newData: newData,
                currentRowData: currentRowData,
                defaultFlag: currentRowData.personal_profi_default
            });
        } else if (field == "probe") {
            checkProbe(newData, (value as boolean), currentRowData);
        } else if (field == "profi") {
            setProfiFlag({
                personal_id: currentRowData.personal_id,
                tarif_id: currentRowData.tarif_id,
                newData: newData,
                currentRowData: currentRowData
            });
            checkProfi(newData, newData.profi, currentRowData);
        } else if (field == "km_geld_kunde" || field == "km_geld") {
            const km_geld = ("km_geld" in newData) ? newData.km_geld : currentRowData.km_geld;
            const km_geld_kunde = ("km_geld_kunde" in newData) ? newData.km_geld_kunde : currentRowData.km_geld_kunde;
            if (km_geld_kunde < km_geld) {
                my_notify(
                    "warning",
                    "Bitte KM Geld kontrollieren, Kilometergeld ist größer als das dem Kunden verrechnete Kilometergeld.",
                    2000
                );
            }
        }
    };

    // only set profi flag in special cases
    // we are only allowed to set the flag, if you are an admin OR tarif is default tarif
    // this function is triggered after editing the cells personal_id, tarif_id and profi
    const setProfiFlag = (data: {
        personal_id: number | null, tarif_id: number,
        newData: Mission, currentRowData: Mission, defaultFlag?: boolean | null
    }) => {
        const {personal_id, tarif_id, newData, currentRowData, defaultFlag = null} = data;
        if (personal_id) { //only check, if a personal_id is selected
            // the defaultFlag is set on the record from the personalcombo on change, or is retrieved by the backend on grid store load
            console.debug("Checking if we are allowed to set the profi flag here, default value is:" + defaultFlag);

            if (tarif_id == VEC.DEFAULT_TARIF || hasPermission(["ADM"])) {
                if (typeof defaultFlag == "boolean") {
                    console.debug("YES, an we RESET to the default value");
                    if (currentRowData.profi != defaultFlag) {
                        newData.profi = defaultFlag || false;
                    }
                } else {
                    console.debug("YES, but we LEAVE the profi value as it is");
                }
            } else {
                console.debug("NO, you are not allowed to set the profi flag");
                newData.profi = false;
            }
        }
    };

    //loop through returned multiple project missions for a staff member
    const loopMultipleMissions = (response: Array<Record<string, unknown>>, index: number) => {

        // check sessionstorage, if we should supress this warning for selected day for this browser session
        if (sessionStorage.getItem("dmm_" + project.tag)) {
            console.log("supressing mehrfacheinteilung for this day!");
        } else {
            const einsatz: any = response[index];
            let msg: string;
            if (einsatz.checkin && einsatz.checkout) {
                msg = `Dieser Mitarbeiter ist auch bei ${einsatz.name} von ${einsatz.checkin}h bis ${einsatz.checkout}h eingeteilt, soll er dort gelöscht werden?`;
            } else {
                msg = `Dieser Mitarbeiter ist auch bei ${einsatz.name} eingeteilt, soll er dort gelöscht werden?`;
            }

            const dialog = custom({
                title: "Achtung Mehrfacheinteilung!",
                messageHtml: msg,
                buttons: [
                    {
                        text: "Ja", onClick: () => {
                            return "yes";
                        }
                    },
                    {
                        text: "Nein, und frage nicht mehr für diesen Tag!",
                        onClick: () => {
                            return "no";
                        }
                    },
                    {
                        text: "Nein",
                        onClick: () => {
                            return "cancel";
                        }
                    }
                ]
            });
            dialog.show().then((dialogResult: any) => {
                if (dialogResult == "yes") {
                    deleteMultipleMission(einsatz.id);
                } else if (dialogResult == "no") {
                    // set sessionstorage to supress future Mehrfacheinteilungs popups clientside
                    sessionStorage.setItem("dmm_" + project.tag, "true");
                }

                if (index + 1 < response.length) {
                    loopMultipleMissions(response, index + 1);
                }
            });
        }
    };

    //delete a multiple mission with the given id on the server
    const deleteMultipleMission = (id: number) => {
        fetchBackend(`/einsatz/deletemultiplemission?id=${id}`)
        .then(handleMinimalErrors)
        .then(() => {
            console.info("deletemultiplemission successful");
        });
    };

    const changeSelectedRowCell = (dataField: string, value: string | number | Date | undefined) => {
        const rowKeys = grid.instance.getSelectedRowKeys();
        if (rowKeys.length > 40) {
            // above 40 rows, its faster to use beginUpdate(), below not, because for with beginUpdate()
            // every batch editing checkbox, onEditingStart and checkEditAllowed will be called
            grid.instance.beginUpdate(); //performance issue with/without
        }
        for (const rowKey of rowKeys) {
            const rowIndex = grid.instance.getRowIndexByKey(rowKey);
            if (grid.instance.cellValue(rowIndex, dataField) != value) {
                grid.instance.cellValue(rowIndex, dataField, value);
            }
        }
        if (rowKeys.length > 40) {
            grid.instance.endUpdate();
        }
    };

    return {
        changeSelectedRowCell,
        setCellValue
    };
};

const useToolbarTop = (grid: any, refreshBtn: () => dxToolbarItem, resetBtn: (otherKeys?: string[] | undefined) => dxToolbarItem,
                       changeSelectedRowCell: (dataField: string, value: string | number | Date | undefined) => void) => {

    const showCrewleadData = ref(false);

    const toolbar = {
        items: [
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                template: "crewleaddata_apply",
                options: {
                    icon: "fas fa-clipboard-list",
                    type: "default",
                    stylingMode: "outlined",
                    text: "Crewleiterdaten übernehmen",
                    onClick: async () => {

                        // get "abzug" tarif id
                        let penalty = null;
                        const data = await tarifComboStore.load();
                        penalty = (data as Array<any>).find(x => x.label === "Abzug");
                        if(!penalty) {
                            my_notify("error", "Tarif 'Abzug' nicht gefunden!");
                        }

                        const rowData = grid.instance.getSelectedRowsData();
                        for (const r of rowData) {
                            const rowIndex = grid.instance.getRowIndexByKey(r["id"]);
                            if(r["r_status"] === VEC.CREWLEAD_STATUS["NO_PRESENCE"] && penalty) {
                                // set "abzug" tarif
                                if(r["tarif_id"]!=penalty["id"] ) {
                                    grid.instance.cellValue(rowIndex, "tarif_id", penalty["id"]);
                                }
                                // set checkin == checkout
                                if(r["checkin"]!=r["checkout"]) {
                                  grid.instance.cellValue(rowIndex, "checkout", r["checkin"]);
                                }
                            } else {
                              if(r["r_tarif_id"] && r["r_tarif_id"] != r["tarif_id"]) {
                                  grid.instance.cellValue(rowIndex, "tarif_id", r["r_tarif_id"]);
                              }
                              if(r["r_kommt"] && r["r_kommt"] != r["checkin"]) {
                                  grid.instance.cellValue(rowIndex, "checkin", r["r_kommt"]);
                              }
                              if(r["r_geht"] && r["r_geht"] != r["checkout"]) {
                                  grid.instance.cellValue(rowIndex, "checkout", r["r_geht"]);
                              }
                              if(r["r_pause"] && r["r_pause"] != r["pause"]) {
                                  grid.instance.cellValue(rowIndex, "pause", r["r_pause"]);
                              }
                            }
                        }
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    hint: "setze markierte Einträge auf 'geplant'",
                    icon: 0, // template-var icon and text are fixed for button template
                    text: "geplant",
                    template: "stateSelectBoxItem",
                    onClick: (e:any) => {
                        changeSelectedRowCell("status", e.component.option("icon"));
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    hint: "setze markierte Einträge auf 'eingeteilt'",
                    icon: 1,
                    text: "eingeteilt",
                    template: "stateSelectBoxItem",
                    onClick: (e:any) => {
                        changeSelectedRowCell("status", e.component.option("icon"));
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    hint: "setze markierte Einträge auf 'informiert'",
                    icon: 2,
                    text: "informiert",
                    template: "stateSelectBoxItem",
                    onClick: (e:any) => {
                        changeSelectedRowCell("status", e.component.option("icon"));
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    hint: "setze markierte Einträge auf 'abgeschlossen'",
                    icon: 3,
                    text: "abgeschlossen",
                    template: "stateSelectBoxItem",
                    onClick: (e:any) => {
                        changeSelectedRowCell("status", e.component.option("icon"));
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    hint: "setze markierte Einträge auf 'gesperrt'",
                    icon: 4,
                    text: "gesperrt",
                    template: "stateSelectBoxItem",
                    onClick: (e:any) => {
                        changeSelectedRowCell("status", e.component.option("icon"));
                    }
                }
            },
            {
                location: "before",
                locateInMenu: "auto",
                template: "lookup", // cannot use Lookup in a Toolbar Menu via widget, so we use template
                options: {
                    dataSource: {
                        store: personalComboStore,
                        //paginate: false // show all entries on dropdown
                    },
                    hint: "Personal (Mehrfacheinteilung)",
                    placeholder: "Personal",
                    width: 90,
                    valueExpr: "id",
                    displayExpr: "value",
                    stylingMode: "underlined",
                    showCancelButton:false,
                    wrapItemText: true,
                    dropDownOptions: {hideOnOutsideClick:true, showTitle: false, minWidth:220},
                    onValueChanged: (e) => {
                        changeSelectedRowCell("personal_id", e.value);
                    }
                } as DxLookup
            },
            {
                location: "before",
                locateInMenu: "auto",
                template: "lookup", // cannot use Lookup in a Toolbar Menu via widget, so we use template
                options: {
                    dataSource: {
                        store: tarifComboStore,
                        paginate: false // show all entries on dropdown
                    },
                    hint: "Tätigkeit (Mehrfacheinteilung)",
                    placeholder: "Tätigkeit",
                    width: 90,
                    valueExpr: "id",
                    displayExpr: "label",
                    stylingMode: "underlined",
                    showCancelButton:false,
                    wrapItemText: true,
                    dropDownOptions: {hideOnOutsideClick:true, showTitle: false, minWidth:120},
                    onValueChanged: (e) => {
                        if(e.value) {
                            changeSelectedRowCell("tarif_id", e.value);
                            e.component!.reset();
                        }
                    }
                } as DxLookup
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxDateBox",
                options: {
                    hint: "von (Mehrfacheinteilung)",
                    placeholder: "von",
                    width: 40,
                    stylingMode: "underlined",
                    type: "time",
                    displayFormat: "shortTime",
                    useMaskBehavior: true,
                    showDropDownButton: false,
                    onEnterKey: (e) => {
                        changeSelectedRowCell("checkin", e.component!.instance().option("value"));
                        e.component!.instance().reset();
                    }
                } as dxDateBoxOptions
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxDateBox",
                options: {
                    hint: "bis (Mehrfacheinteilung)",
                    placeholder: "bis",
                    width: 40,
                    stylingMode: "underlined",
                    type: "time",
                    displayFormat: "shortTime",
                    useMaskBehavior: true,
                    showDropDownButton: false,
                    onEnterKey: (e) => {
                        changeSelectedRowCell("checkout", e.component!.instance().option("value"));
                        e.component!.instance().reset();
                    }
                } as dxDateBoxOptions
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxNumberBox",
                options: {
                    format: hourFormat,
                    value: undefined,
                    width: 45,
                    stylingMode: "underlined",
                    hint: "Anfahrt (Mehrfacheinteilung)",
                    placeholder: "Anfahrt",
                    onEnterKey: (e) => {
                        changeSelectedRowCell("anfahrt", e.component!.instance().option("value"));
                        e.component!.instance().reset();
                    }
                } as dxNumberBoxOptions
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxNumberBox",
                options: {
                    format: hourFormat,
                    value: undefined,
                    stylingMode: "underlined",
                    width: 40,
                    hint: "Pause bezahlt (Mehrfacheinteilung)",
                    placeholder: "Pause",
                    onEnterKey: (e) => {
                        changeSelectedRowCell("pause2", e.component!.instance().option("value"));
                        e.component!.instance().reset();
                    }
                } as dxNumberBoxOptions
            },
            {
                location: "before",
                locateInMenu: "auto",
                widget: "dxNumberBox",
                options: {
                    format: hourFormat,
                    value: undefined,
                    stylingMode: "underlined",
                    width: 40,
                    hint: "h-min (Mehrfacheinteilung)",
                    placeholder: "h-min",
                    onEnterKey: (e) => {
                        changeSelectedRowCell("stunden_min", e.component!.instance().option("value"));
                        e.component!.instance().reset();
                    }
                } as dxNumberBoxOptions
            },
            {
                location: "before",
                locateInMenu: "auto",
                showText: "inMenu",
                widget: "dxButton",
                options: {
                    hint: "Markierte Einsätze löschen",
                    icon: "trash",
                    text: "Markierte Einsätze löschen",
                    onClick: () => {
                        const instance = grid.instance
                        instance.beginUpdate();
                        for (const key of instance.getSelectedRowKeys()) {
                            const rowIndex = instance.getRowIndexByKey(key);
                            instance.deleteRow(rowIndex);
                        }
                        instance.endUpdate();
                    }
                }
            },
            "addRowButton",
            toolbarSaveButton,
            toolbarRevertButton,
            {
                location: "after",
                locateInMenu: "never",
                showText: "inMenu",
                widget: "dxButton", // needed here or else the button is wrong inside the menu
                template: "crewleaddata_toggle",
                options: {
                    hint: "Daten aus Crewleiterliste ein/ausblenden",
                    icon: "fas fa-clipboard-list",
                    text: "Crewleiterdaten",
                    onClick: () => {
                        showCrewleadData.value = !showCrewleadData.value;
                        grid.instance.repaint();
                    }
                }
            },
            "columnChooserButton",
            resetBtn(),
            refreshBtn()
        ]
    } as Toolbar;

    return {
        toolbar,
        showCrewleadData
    };
};

const useToolbarBottom = (project: Project, grid: { instance: DataGrid }) => {
    const showLoadingIndicator = ref(false);

    // text changed via onloaded
    const buttonContactPerson = reactive({
        widget: "dxButton",
        location: "after",
        locateInMenu: "auto",
        options: {
            text: "Ansprechpartner",
            icon: "tel",
            hint: "Ansprechpartner",
            onClick: () => {
                createCustomPopup({
                    propsData: {
                        childComponent: "MissionGridContactPerson",
                        childProps: {projectdayId: project.projekttag_id},
                        popupTitle: "Ansprechpartner: " + missionTitle(project.name, project.tag, project.kunde_name, project.ort),
                        popupWidth: 800,
                        popupHeight: 600,
                        popupHidden: () => {
                            grid.instance.refresh();
                        }
                    }
                });
            }
        } as dxButtonOptions
    });

    // has to be a ref, as buttonContactPerson gets changed loaded
    const toolbarBottomItems = ref([
        buttonContactPerson,
        {
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "Material",
                icon: "fas fa-truck-loading",
                hint: "Material",
                onClick: () => {
                    createCustomPopup({
                        propsData: {
                            childComponent: "MissionGridMaterial",
                            childProps: {projectdayId: project.projekttag_id},
                            popupTitle: "Material: " + missionTitle(project.name, project.tag, project.kunde_name, project.ort),
                            popupWidth: 1100,
                            popupHeight: 600
                        }
                    });
                }
            } as dxButtonOptions
        },
        {
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "CL-Mail",
                icon: "message",
                onClick: () => {
                    sendMail(project, showLoadingIndicator);
                }
            } as dxButtonOptions
        },
        {
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "CL-Ansicht",
                icon: "fas fa-clipboard-list",
                onClick: () => {
                    window.open(`/einsatz/report/${project.projekttag_id}/crewleitung`);
                }
            } as dxButtonOptions
        },
        {
            widget: "dxMenu",
            location: "after",
            locateInMenu: "auto",
            options: {
                items: [{
                    text: "PDFs",
                    icon: "print",
                    items: [
                        {
                            text: "Checkliste",
                            icon: "fas fa-clipboard-check",
                            onClick: () => {
                                downloadFile(`/index/checkliste/id/${project.projekttag_id}`);
                            }
                        },
                        {
                            text: "CL-Liste",
                            icon: "fas fa-clipboard-list",
                            onClick: () => {
                                downloadFile(`/index/crewleiterliste/id/${project.projekttag_id}`);
                            }
                        },
                        {
                            text: "Materialliste",
                            icon: "fas fa-truck",
                            onClick: () => {
                                downloadFile(`/index/materialliste/id/${project.projekttag_id}`);
                            }
                        }
                    ]
                }]
            }
        },
        {
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "SMS",
                icon: "fas fa-mobile-alt",
                onClick: () => {
                    sendSMS(grid, project, showLoadingIndicator);
                }
            } as dxButtonOptions
        }
    ] as Array<dxToolbarItem>);

    const formBottomItems = reactive([{
        stylingMode: "underlined",
        buttons: [{
            location: "before",
            name: "ma_kunde",
            options: {
                text: "MA Kunde:",
                focusStateEnabled: false,
                hoverStateEnabled: false,
                stylingMode: "text",
                disabled: true
            }
        }],
        onChange: (e: any) => {
            fetchBackend("/projekttag/changevalue", {
                method: "POST",
                body: new URLSearchParams({
                    projekttag_id: project.projekttag_id.toString(),
                    wanted: e.event.target.value
                })
            }).then(handleErrors);
        },
        value: 0
    }, {
        stylingMode: "underlined",
        buttons: [{
            location: "before",
            name: "ma_extra",
            options: {
                text: "MA Extra:",
                focusStateEnabled: false,
                hoverStateEnabled: false,
                stylingMode: "text",
                disabled: true
            }
        }],
        onChange: (e: any) => {
            fetchBackend("/projekttag/changevalue", {
                method: "POST",
                body: new URLSearchParams({
                    projekttag_id: project.projekttag_id.toString(),
                    additional: e.event.target.value
                })
            }).then(handleErrors);
        },
        value: 0
    }, {
        height: 43,
        stylingMode: "underlined",
        placeholder: "Memo",
        onChange: (e: any) => {
            fetchBackend("/projekttag/changevalue", {
                method: "POST",
                body: new URLSearchParams({
                    projekt_id: project.id.toString(),
                    memo: e.event.target.value
                })
            }).then(handleErrors);
        },
        value: ""
    }] as Array<DxNumberBox | DxTextArea>);

    return {toolbarBottomItems, formBottomItems, showLoadingIndicator, buttonContactPerson};
};

const {checkEditAllowed} = usePermissionHandling(grid);
const {changeSelectedRowCell, setCellValue} = useCRUD(grid, checkEditAllowed, project, defaultTarifLabel);
const {toolbar, showCrewleadData} = useToolbarTop(grid, refreshBtn, resetBtn, changeSelectedRowCell);
const {toolbarBottomItems, formBottomItems, showLoadingIndicator, buttonContactPerson} = useToolbarBottom(project, grid);

onMounted(() => {
    onPermission(["ADM", "GFA", "BOF", "HDI", "DIS"], allowEditing);
    configureStateWithoutSelection(grid.instance, "missiondetail");

    disablePaging();

    // enable multiple selection
    const selection = grid.instance.option("selection")!;
    selection["mode"] = "multiple";
    selection["deferred"] = false;
    selection["selectAllMode"] = "page";
    grid.instance.option("selection", selection);

    const device = devices.real();

    // more space on desktop (also fixes initial need for refresh layout with columnHiding on in popups)
    if (device.deviceType == "desktop") {
        grid.instance.option("columnHidingEnabled", false);
        grid.instance.option("allowColumnResizing", true);
        grid.instance.option("columnResizingMode", "widget");
    }

    dataSource.store().on("loading", (loadOptions: LoadOptions) => {
        loadOptions["userData"] = {
            ...loadOptions["userData"],
            projectday: props.projectdayId
        };
    });

    //clear combo caches
    personalComboStore.clearRawDataCache();
    tarifComboStore.clearRawDataCache();

});
onBeforeUnmount(() => {
    dataSource.store().off("loading");
});


const columns: Array<Column> = [
    {
        caption: "#",
        cellTemplate: (cellElement, cellInfo) => {
            cellElement.textContent = (cellInfo.rowIndex! + 1).toString();
        },
        width: 35,
        alignment: "right",
        allowEditing: false,
        allowFiltering: false
    },
    stateColumn({
        setCellValue: (newData, value, currentRowData) => setCellValue("status", newData, value, currentRowData)
    }),
    defaultColumn({
        dataField: "personal_id",
        caption: "Personal",
        minWidth:100,
        lookup: {
            dataSource: personalComboStore,
            allowClearing: true,
            valueExpr: "id",
            displayExpr: "value"
        },
        encodeHtml: false,
        // signal frontend if personal was added by crewlead
        calculateDisplayValue: (rowData: any) => {
            let value = rowData.personal_name || ""; // does not preload personalcombo
            if(rowData.r_added_by_crewlead){
                value += '<br><span class="diff">(von Crewlead)</span>';
            }
            if(showCrewleadData.value && (!rowData.r_signature && !rowData.gruppe)) {
                value += '<br><span class="diff not_reached">Unterschrift fehlt!</span>';
            }
            return value
        },
        calculateSortValue: "personal_name",
        setCellValue: (newData, value, currentRowData) => setCellValue("personal_id", newData, value, currentRowData)
    }),
    defaultColumn({
        dataField: "tarif_id",
        caption: "Tätigkeit",
        lookup: {
            dataSource: tarifComboStore,
            valueExpr: "id",
            displayExpr: "label",
            allowClearing: false
        },
        validationRules: [{type: "required"}],
        encodeHtml: false,
        calculateDisplayValue: (rowData: any) => {
            let value = rowData.tarif_label;
            if(showCrewleadData.value){
                if(rowData.r_status === VEC.CREWLEAD_STATUS["NO_PRESENCE"]) {
                    if (rowData.tarif_label != "Abzug") {
                        value += `<br><span class="diff not_reached">(nicht erschienen)</span>`;
                    }
                } else if(rowData.r_tarif_id && rowData.r_tarif_id !== rowData.tarif_id) {
                    // get label from tarif datasource
                    tarifComboStore.byKey(rowData.r_tarif_id).then((tarif: any) => {
                        value += `<br><span class="diff">${tarif.label}</span>`;
                    });
                }
            }
            return value
        },
        calculateSortValue: "tarif_label",
        setCellValue: (newData, value, currentRowData) => setCellValue("tarif_id", newData, value, currentRowData),
    }),
    timeColumn({
        dataField: "checkin",
        caption: "von",
        encodeHtml: false,
        calculateDisplayValue: (rowData: any) => {
            let value = rowData.checkin instanceof Date ? formatDate(rowData.checkin, "HH:mm") : rowData.checkin || "";
            if(showCrewleadData.value && rowData.r_kommt && rowData.r_kommt != rowData.checkin){
                value += `<br><span class="diff">${rowData.r_kommt}</span>`;
            }
            return value
        },
        calculateSortValue: "checkin",
        setCellValue: (newData, value, currentRowData) => setCellValue("checkin", newData, value, currentRowData)
    }),
    timeColumn({
        dataField: "checkout",
        caption: "bis",
        encodeHtml: false,
        calculateDisplayValue: (rowData: any) => {
            let value = rowData.checkout instanceof Date ? formatDate(rowData.checkout, "HH:mm") : rowData.checkout || "";
            if(showCrewleadData.value && rowData.r_geht && rowData.r_geht != rowData.checkout){
                value += `<br><span class="diff">${rowData.r_geht}</span>`;
            }
            return value
        },
        calculateSortValue: "checkin",
        setCellValue: (newData, value, currentRowData) => setCellValue("checkout", newData, value, currentRowData)
    }),
    hourColumn({
        dataField: "anfahrt",
        validationRules: [{
            type: "range",
            min: 0,
            max: 99.99
        }, {
            type: "required"
        }],
        setCellValue: (newData, value, currentRowData) => setCellValue("anfahrt", newData, value, currentRowData)
    }),
    hourColumn({
        dataField: "pause2",
        caption: "Pause bezahlt",
        validationRules: [{
            type: "range",
            min: 0,
            max: 99.99
        }, {
            type: "required"
        }],
        setCellValue: (newData, value, currentRowData) => setCellValue("pause2", newData, value, currentRowData)
    }),
    hourColumn({
        dataField: "pause",
        caption: "Pause unbezahlt (wird von den Stunden abgezogen)",
        validationRules: [{
            type: "range",
            min: 0,
            max: 99.99
        }, {
            type: "required"
        }],
        encodeHtml: false,
        calculateDisplayValue: (rowData: any) => {
            let value = formatNumber(parseFloat(rowData.pause), hourFormat);
            if(showCrewleadData.value && rowData.r_pause && rowData.r_pause != rowData.pause){
                value += `<br><span class="diff">${formatNumber(parseFloat(rowData.r_pause), hourFormat)}</span>`;
            }
            return value
        },
        calculateSortValue: "pause",
        setCellValue: (newData, value, currentRowData) => setCellValue("pause", newData, value, currentRowData)
    }),
    currencyColumn({
        config: {
            dataField: "km_geld",
            caption: "km",
            setCellValue: (newData, value, currentRowData) => setCellValue("km_geld", newData, value, currentRowData)
        },
        required: true,
        min: -99999.99,
        max: 99999.99
    }),
    currencyColumn({
        config: {
            dataField: "km_geld_kunde",
            caption: "km Kunde",
            setCellValue: (newData, value, currentRowData) => setCellValue("km_geld_kunde", newData, value, currentRowData)
        },
        required: true,
        min: -99999.99,
        max: 99999.99
    }),
    currencyColumn({
        config: {
            dataField: "pauschalen",
            caption: "Spesen",
            allowEditing: hasPermission(["ADM", "GFA", "BOF", "HDI"])
        },
        required: true,
        min: -99999.99,
        max: 99999.99
    }),
    boolColumn({
        dataField: "profi",
        setCellValue: (newData, value, currentRowData) => {
            setCellValue("profi", newData, value, currentRowData);
        }
    }),
    boolColumn({
        dataField: "probe",
        caption: "Noob",
        setCellValue: (newData, value, currentRowData) => {
            setCellValue("probe", newData, value, currentRowData)
        }
    }),
    boolColumn({
        dataField: "crewleader",
        caption: "Chef"
    }),
    hourColumn({
        dataField: "stunden",
        caption: "Stunden",
        allowEditing: false,
        visible: false
    }),
    hourColumn({
        dataField: "stunden_min",
        caption: "h-min",
        visible: false,
        validationRules: [{
            type: "range",
            min: 0,
            max: 13.00
        }],
        setCellValue: (newData, value, currentRowData) => setCellValue("stunden_min", newData, value, currentRowData)
    }),
    currencyColumn({
        config: {
            dataField: "stundensatz",
            allowEditing: false,
            visible: false
        },
        required: false
    }),
    currencyColumn({
        config: {
            dataField: "stundensatz_anfahrt",
            caption: "Stundensatz Anfahrt",
            allowEditing: false,
            visible: false
        },
        required: false
    }),
    placeHolderColumn,
    {
        type: "buttons",
        showInColumnChooser: false,
        buttons: [
            {name: "edit"},
            {name: "delete"}
        ]
    }
];

const sendMail = (project: Project, showLoadingIndicator: Ref<boolean>) => {

    showLoadingIndicator.value = true;
    fetchBackend("/index/settingsselect", {
        method: "POST",
        body: new URLSearchParams({
            keys: JSON.stringify(["crewleader_email"])
        })
    })
    .then(handleErrors)
    .then((result) => {
        showLoadingIndicator.value = false;
        const dialog = custom({
            title: "Text an den Crewleiter",
            dragEnabled: false, // minimize bug where mousescroll would drag the window instead of scrolling the textarea content
            messageHtml: `
                        <div class="dx-textarea dx-textbox dx-texteditor dx-editor-outlined">
                          <textarea
                            id="crewleader_mail_body"
                            rows="25"
                            cols="90"
                            class="dx-texteditor-input">${result.crewleader_email || ""}</textarea>
                        </div>`,
            buttons: [
                {
                    text: "Crewleitermail senden", onClick: () => {
                        showLoadingIndicator.value = true;
                        const mail_body = (document.getElementById("crewleader_mail_body") as HTMLTextAreaElement).value;

                        fetchBackend("/index/crewleiteremail", {
                            method: "POST",
                            body: new URLSearchParams({
                                id: project.projekttag_id.toString(),
                                mail_body: mail_body
                            })
                        })
                        .then(handleErrors)
                        .then(() => {
                            showLoadingIndicator.value = false;
                        });

                        return "yes";
                    }
                },
                {text: "Abbrechen"}
            ]
        });
        dialog.show();
    });
};

const sendSMS = (grid: { instance: DataGrid }, project: Project, showLoadingIndicator: Ref<boolean>) => {
    if (grid.instance.hasEditData()) {
        my_notify("info", "Ungesicherte Daten, bitte speichern!");
        return;
    }
    const mission_ids = grid.instance.getSelectedRowKeys();
    if (!mission_ids.length) {
        my_notify("info", "Keine Einsätze markiert!");
        return;
    }

    showLoadingIndicator.value = true;
    fetchBackend("/einsatz/sms", {
        method: "POST",
        body: JSON.stringify({
            projekttag_id: project.projekttag_id,
            mission_ids: mission_ids
        })
    })
    .then(handleErrors)
    .then((result) => {
        showLoadingIndicator.value = false;
        createCustomPopup({
            propsData: {
                childComponent: "MissionGridSMS",
                childProps: {data: result},
                popupTitle: "SMS Vorlagen:",
                popupWidth: 655,
                popupHeight: 490
            }
        });
    });
};
</script>

<style lang="scss" scoped>

  h1 {
    font-size: 20px;
    margin-left: 10px
  }

  .dx-toolbar.bottom-toolbar {
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;
  }

  #mission-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
    background-color: #fff;

    .dx-texteditor {
      margin-top: auto;
      width: 115px;
    }

    :nth-child(3) {
      flex-grow: 1;
      min-width: 360px; //iphone 5S
      max-width: 500px;
      margin-left: auto;
    }
  }

  ::v-deep(.diff) {
    font-size: 10px;
    color:#959595;
    &.not_reached {
      color: #ff0000;
    }
  }
</style>
