// initialize fontawesome with tree shaking
import {dom, library, config} from "@fortawesome/fontawesome-svg-core"
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons/faCalendarAlt"
import {faSmile} from "@fortawesome/free-regular-svg-icons/faSmile"
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook"
import {faBug} from "@fortawesome/free-solid-svg-icons/faBug"
import {faCalculator} from "@fortawesome/free-solid-svg-icons/faCalculator"
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons/faCalendarDay"
import {faCalendarMinus} from "@fortawesome/free-solid-svg-icons/faCalendarMinus"
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus"
import {faClipboardCheck} from "@fortawesome/free-solid-svg-icons/faClipboardCheck"
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList"
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins"
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt"
import {faFileExport} from "@fortawesome/free-solid-svg-icons/faFileExport"
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar"
import {faFileUpload} from "@fortawesome/free-solid-svg-icons/faFileUpload"
import {faHandHoldingUsd} from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd"
import {faHistory} from "@fortawesome/free-solid-svg-icons/faHistory"
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock"
import {faLockOpen} from "@fortawesome/free-solid-svg-icons/faLockOpen"
import {faMobileAlt} from "@fortawesome/free-solid-svg-icons/faMobileAlt"
import {faPeopleCarry} from "@fortawesome/free-solid-svg-icons/faPeopleCarry"
import {faPhoneVolume} from "@fortawesome/free-solid-svg-icons/faPhoneVolume"
import {faReceipt} from "@fortawesome/free-solid-svg-icons/faReceipt"
import {faTasks} from "@fortawesome/free-solid-svg-icons/faTasks"
import {faTruck} from "@fortawesome/free-solid-svg-icons/faTruck"
import {faTruckLoading} from "@fortawesome/free-solid-svg-icons/faTruckLoading"
import {faUserGraduate} from "@fortawesome/free-solid-svg-icons/faUserGraduate"
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers"
import {faUserTie} from "@fortawesome/free-solid-svg-icons/faUserTie"
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt"
import {faObjectGroup} from "@fortawesome/free-solid-svg-icons/faObjectGroup"
import {faEuroSign} from "@fortawesome/free-solid-svg-icons/faEuroSign"
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey"
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay"
import {faStop} from "@fortawesome/free-solid-svg-icons/faStop"
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf"
import {faSignature} from "@fortawesome/free-solid-svg-icons/faSignature"

// Add all icons to the library, so you can use it in your page
library.add(
    faCalendarAlt, faCalculator, faLock, faLockOpen, faFileExport, faUsers, faUserTie,
    faCalendarDay, faCoins, faTruck, faFileUpload, faReceipt, faTruckLoading,
    faSmile, faHandHoldingUsd, faPhoneVolume, faBug, faBook, faHistory,
    faPeopleCarry, faMobileAlt, faTasks, faClipboardCheck, faClipboardList,
    faCalendarPlus, faCalendarMinus, faExternalLinkAlt, faUserGraduate,
    faFileInvoiceDollar, faSignOutAlt, faObjectGroup, faEuroSign, faKey,
    faPlay, faStop, faFilePdf, faSignature
);
dom.watch(); // for static icons rendered via dx-button icon
// for dynamic icons which are changed based on state, use <font-awesome-icon :icon="icon" />, see https://github.com/FortAwesome/vue-fontawesome/issues/226#issuecomment-613492242

// fix fontawesome icons onClick Events not working on buttons in grid columns
// https://supportcenter.devexpress.com/ticket/details/t742298/datagrid-treelist-the-click-event-of-a-command-button-is-not-raised-when-a-font-awesome
config.autoReplaceSvg = "nest";


