<template>
  <div class="dx-card">
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      :toolbar="toolbar"
      @initialized="saveGridInstance"
    />
  </div>
</template>
<script lang="ts">
import {
    actionDownload,
    currencyColumn,
    dateColumn,
    defaultColumn,
    hourColumn,
    placeHolderColumn
} from "@/utils/columns";
import {fixPopupGridLayout, useGrid} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import {Column, Toolbar} from "devextreme/ui/data_grid";
import {defineComponent, onBeforeUnmount, onMounted} from "vue";

export default defineComponent({
    components: {
        DxDataGrid
    },
    props: {
        projectId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {
        const dataSource = gridDataSource({
            read: "/rechnung/index"
        },{
            onLoaded: () => {
                fixPopupGridLayout(grid.instance);
            }
        });

        const {
            grid, saveGridInstance, disablePaging, disableColumnChooser, disableState, refreshBtn
        } = useGrid(dataSource);

        onMounted(() => {
            disablePaging();
            disableColumnChooser();
            disableState();

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    project_id: props.projectId,
                    noExport: 1
                };
            });
        });
        onBeforeUnmount(() => {
            dataSource.store().off("loading");
        });

        const toolbar = {
            items: [
                refreshBtn()
            ]
        } as Toolbar;

        const columns: Array<Column> = [
            defaultColumn({
                dataField: "nummer",
                dataType: "number"
            }),
            dateColumn({
                dataField: "datum"
            }),
            currencyColumn({
                config: {
                    dataField: "netto"
                }
            }),
            currencyColumn({
                config: {
                    dataField: "brutto",
                }
            }),
            hourColumn({
                dataField: "stunden_verrechnet",
                caption: "verrechnete Stunden"
            }),
            dateColumn({
                dataField: "bezahlt_am"
            }),
            placeHolderColumn,
            {
                type: "buttons",
showInColumnChooser: false,
                alignment: "left",
                buttons: [
                    actionDownload("Rechnung herunterladen")
                ]
            }
        ];

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns,
            toolbar
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
