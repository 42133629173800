<template>
  <dx-scroll-view
    height="auto"
  >
    <slot />
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  }
};
</script>

<style lang="scss">
  @import "../themes/dx-styles.scss";
</style>
