import config from "devextreme/core/config";
import {loadMessages, locale} from "devextreme/localization";

import deMessages from "devextreme/localization/messages/de.json";
// default grid options (single options are overwritable later on)
import DataGrid from "devextreme/ui/data_grid";
import {createPinia} from "pinia"
import * as Sentry from "@sentry/vue";
import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import "devextreme/dist/css/dx.common.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./utils/icons"; // init fontawesome
import { licenseKey } from '../devextreme-license';

deMessages.de["dxDataGrid-filterRowShowAllText"] = "";
loadMessages(deMessages);
locale("de");

config({
    licenseKey: licenseKey,
    defaultCurrency: "EUR"
});

DataGrid.defaultOptions({
    options: {
        showColumnLines: true,
        showRowLines: false,
        rowAlternationEnabled: true,
        allowColumnReordering: true,
        columnHidingEnabled: true,
        columnAutoWidth: true, // Specifies whether columns should adjust their widths to the content.
        hoverStateEnabled: true,
        remoteOperations: true,
        wordWrapEnabled: false, // TODO make selectable per grid
        cacheEnabled: false, // dont cache page data, query server everytime
        renderAsync: true, // faster loading response
        repaintChangesOnly: true, //faster response on updating multiple rows (missiondetail status for example)
        dateSerializationFormat: "yyyy-MM-dd", // this fixes the sporadic wrong date sent to the server (client recalc triggers the day before), see #78

        // since 22.x necessary,
        // see https://supportcenter.devexpress.com/ticket/details/t1103099/datagrid-lookup-inside-filter-row-not-working-after-v22-1-x
        // and https://supportcenter.devexpress.com/ticket/details/t1109960/datagrid-a-lookup-column-s-filterrow-triggers-an-unnecessary-request-to-the-datagrid-s
        syncLookupFilterValues: false,

        scrolling: {
            rowRenderingMode: "standard", // can be set to "virtual" to increase performance
            columnRenderingMode: "standard"
        },
        selection: {
            mode: "single",
            // does not load all source data, if currently invisible row is selected by state storing
            // needs key property set on store
            // https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/selection/#deferred
            deferred: true
        },
        columnChooser: {
            enabled: true,
            mode: "select"
        },
        sorting: {
            mode: "single"
        },
        paging: {
            enabled: true,
            pageSize: 25
        },
        pager: {
            showInfo: true,
            showNavigationButtons: true,
            showPageSizeSelector: true,
            allowedPageSizes: [25, 50, 100],
            visible: true
        },
        editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            useIcons: true,
            mode: "batch"
            //selectTextOnEditStart: true
            //refreshMode: 'repaint'
        },
        keyboardNavigation: {
            editOnKeyPress: true,
            enterKeyAction: "startEdit",
            enterKeyDirection: "column"
        },
        stateStoring: {
            enabled: true,
            type: "localStorage"
        },
        export: {
            enabled: false
        }
    }
});

// DataGrid.defaultOptions({
//     // https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Methods/#defaultOptionsrule
//     device: { deviceType: "desktop" },  // can also be an array ['phone', 'tablet', 'desktop' ]
//     options: {
//         //columnHidingEnabled: false,
//         //allowColumnResizing: true
//     }
// });

const app = createApp(App);

if (typeof import.meta.env.VITE_VEC_SENTRY_DSN !== 'undefined') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_VEC_SENTRY_DSN.toString(),
        environment: import.meta.env.MODE,
        ignoreErrors: [
            "ResizeObserver loop completed with undelivered notifications",
            "Non-Error promise rejection captured with value: you are logged out",
            "Non-Error promise rejection captured with value: server error",
            "this._overlay is null",
            "this._contentReadyAction is not a function",
            "Failed to update a ServiceWorker for scope",
            /^ServiceWorker script at .* for scope .* encountered an error during installation\.$/,
            "service-worker.js load failed",
            "Load failed",
            "NetworkError when attempting to fetch resource",
            "Importing a module script failed",
            "newestWorker is null",
        ],
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i
        ]
    });
}

app
.use(createPinia())
.use(router)
.component('font-awesome-icon', FontAwesomeIcon) // for dynamic icons which are changed based on state, see ./utils/icons.ts
.mount("body");
