<template>
  <dx-popup
    ref="popup"
    v-model:visible="popupVisible"
    v-model:full-screen="popupFullscreen"
    :title="popupTitle"
    :width="dimensions.w"
    :height="dimensions.h"
    :resize-enabled="true"
    :drag-enabled="true"
    :hide-on-outside-click="false"
    :show-close-button="true"
    :shading="popupShading"
    :toolbar-items="toolbarItems"
    @hidden="onHidden"
    @resize-end="onResizeEnd"
  >
    <dx-scroll-view>
      <component
        :is="childComponent"
        :popup-instance="popupInstance"
        v-bind="childProps"
      />
    </dx-scroll-view>
  </dx-popup>
</template>
<script lang="ts">
import {DxPopup} from "devextreme-vue/popup";
import {DxScrollView} from "devextreme-vue/scroll-view";
import Popup from "devextreme/ui/popup"
import {defineComponent, onMounted, reactive, ref} from "vue";
import BillGrid from "./bill-grid.vue"
import BillProjectDaysGrid from "./bill-project-days-grid.vue"
import BruttoDiffGrid from "./brutto-diff-grid.vue"
import ChecklistGrid from "./checklist-grid.vue"
import CustomerBillGrid from "./customer/bill-grid.vue"
import CustomerMissionGrid from "./customer/mission-grid.vue"
import MaterialSetGrid from "./material-set-grid.vue"
import MissionGridContactPerson from "./mission-grid-contactperson.vue"
import MissionGridMaterial from "./mission-grid-material.vue"
import MissionGridSMS from "./mission-grid-sms.vue"
import MissionGrid from "./mission-grid.vue"
import PersonalMergeGrid from "./personal-merge-grid.vue"
import ProjectMergeGrid from "./project-merge-grid.vue"

const frontZIndex = "1500"; // devextreme popup base z index is 1500, first popup starts with 1501
const bgZIndex = "1499";

export default defineComponent({

    components: {
        DxPopup,
        DxScrollView,
        // add possible components loaded dynamically
        MissionGrid,
        MissionGridSMS,
        MissionGridContactPerson,
        MissionGridMaterial,
        ChecklistGrid,
        BillProjectDaysGrid,
        BillGrid,
        MaterialSetGrid,
        BruttoDiffGrid,
        ProjectMergeGrid,
        PersonalMergeGrid,
        CustomerBillGrid,
        CustomerMissionGrid
    },
    props: {
        childComponent: {
            type: String,
            default: null
        },
        childProps: {
            type: Object,
            default: null
        },
        popupTitle: {
            type: String,
            default: null
        },
        popupShading: {
            type: Boolean,
            default: true
        },
        popupWidth: {
            type: Number,
            default: 1200
        },
        popupHeight: {
            type: Number,
            default: 600
        },
        popupHidden: {
            type: Function,
            default: null
        },
        popupDestroy: {
            type: Function,
            default: null
        }
    },
    setup: (props) => {
        const popup = ref<DxPopup>();
        const popupInstance = ref<Popup>();
        const popupVisible = ref(false);
        const popupFullscreen = ref(false);

        // restore width and height from localstorage
        const storage = JSON.parse(
            localStorage.getItem(props.childComponent + "-popup-dimensions")!
        ) || {w: props.popupWidth, h: props.popupHeight};
        // get window dimensions, open popup within the window dimensions
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const dimensions = reactive({
            w: (storage["w"] <= windowWidth) ? storage["w"] : windowWidth,
            h: (storage["h"] <= windowHeight) ? storage["h"] : windowHeight
        });

        const toolbarItems = ref([{
            widget: "dxButton",
            options: {
                icon: "spinup",
                stylingMode: "text",
                onClick: function () {
                    // minimize or restore height
                    if (dimensions.h == 42) {
                        dimensions.h = storage["h"]
                    } else {
                        dimensions.h = 42;
                    }
                }
            },
            location: "after"
        }, {
            widget: "dxButton",
            options: {
                icon: "fullscreen",
                stylingMode: "text",
                onClick: function () {
                    popupFullscreen.value = !popupFullscreen.value;
                }
            },
            location: "after"
        }
        ]);

        // onUnmounted(()=>{
        //     console.log("Popup destroyed");
        // });

        onMounted(() => {
            // show popup
            popupVisible.value = true;
            // pass popup component to child component props, which ca be used to hide the popup for example
            popupInstance.value = popup.value!.instance;

            // toggle active popup functionality
            // popup window in dom (inside wrapper div created at the end if page body)
            const popupContentDiv = popup.value!.instance!.content().parentElement as HTMLDivElement;
            popupContentDiv.onmousedown = () => {
                const popupContentWrapper = popupContentDiv.parentElement as HTMLDivElement;

                // on first click on popup (zindex>=1501) or popup is in bg (zindex==1499)
                // reset all popups to 1499
                // bring current popup to front 1500
                if (popupContentWrapper.style.zIndex != frontZIndex) {
                    Array.from(
                        popupContentWrapper.parentElement!.getElementsByClassName("dx-overlay-wrapper dx-popup-wrapper")
                    )
                    .map(element => (element as HTMLDivElement).style.zIndex = bgZIndex);
                    popupContentWrapper.style.zIndex = frontZIndex;
                }
            }
        });

        // destroy all popup traces on hide event
        const onHidden = (e: any) => {
            //callback from parent
            if (props.popupHidden) {
                props.popupHidden();
            }

            // to remove component from its own event handler, use setTimeout
            // see https://supportcenter.devexpress.com/Ticket/Details/T592755/how-to-dispose-popup-on-close
            setTimeout(() => {
                popupInstance.value = undefined; // fix memory leak
                e.component!.dispose(); // devextreme dispose
                props.popupDestroy() // this destroys the vue3 component
            }, 500);
        };

        // save resize data to localstorage
        const onResizeEnd = (e: { component: Popup }) => {
            const width = e.component.option("width");
            const height = e.component.option("height");
            localStorage.setItem(props.childComponent + "-popup-dimensions", JSON.stringify({
                w: width,
                h: height
            }));

        };

        // return data visible to template
        return {
            popup,
            popupVisible,
            popupFullscreen,
            dimensions,
            toolbarItems,
            onHidden,
            onResizeEnd,
            popupInstance
        };
    }
});
</script>

<style lang="scss">
.dx-overlay-content .dx-popup-content > .dx-template-wrapper {
  height: 0;
}
</style>
