export default {
    MAX_INT: 2147483647,
    STATE_PLANED: 0, // red
    STATE_DISPOSED: 1, // orange
    STATE_INFORMED: 2, // green
    STATE_CLOSED: 3, // black
    STATE_LOCKED: 4, // grey
    STATE_SICK_LEAVE: 97, //only used for sickleave, this is not a real state saved to db
    STATE_HOLIDAY: 98, //only used for holiday, this is not a real state saved to db
    STATE_FREE: 99, //this state is not saved in database (so it is save to do a > 0 query on state for example)
    GKK0_WORK0: 99,
    GKK0_WORK1: 0,
    GKK1_WORK1: 2,
    GKK1_WORK0: 1,

    //used for preselection and calculations in mission-grid.vue, see ticket #96
    //also check db triggers, where the default tarif id is used for anfahrt tarif!
    //also in ProjekttagController#updateAction()
    DEFAULT_TARIF: 1,

    CREWLEAD_STATUS: {
        CONFIRMED: 1,
        REACHED: 2,
        NOT_REACHED: 3,
        NO_PRESENCE: 4
    }
}
