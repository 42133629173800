<template>
  <div>
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      :toolbar="toolbar"
      @initialized="saveGridInstance"
    />
  </div>
</template>
<script lang="ts">
import {
    actionDelete,
    actionDownload,
    currencyColumn,
    dateColumn,
    defaultColumn,
    hourColumn,
    placeHolderColumn,
    uniqueValidator
} from "@/utils/columns";
import {
    fixPopupGridLayout,
    toolbarRevertButton,
    toolbarSaveButton,
    useGrid
} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid} from "devextreme-vue/data-grid";
import DxTextArea from "devextreme-vue/text-area";
import {LoadOptions} from "devextreme/data";
import DataGrid, {Column, Toolbar} from "devextreme/ui/data_grid";
import {defineComponent, onBeforeUnmount, onMounted} from "vue";

export default defineComponent({
    components: {
        DxDataGrid,
        DxTextArea  // eslint-disable-line vue/no-unused-components
    },
    props: {
        projectId: {
            type: Number,
            default: null
        },
        billId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {

        // start editing new bill, if billId is set
        const onDataLoaded = () => {
            setTimeout(() => { // timeout, where data should be there to edit
                if (props.billId) {
                    const rowIndex = grid.instance.getRowIndexByKey(props.billId);
                    grid.instance.editRow(rowIndex);
                }
            }, 100);
            fixPopupGridLayout(grid.instance);
        };

        const dataSource = gridDataSource({
            create: "/rechnung/create?project_id=" + props.projectId,
            read: "/rechnung/index",
            update: "/rechnung/update",
            destroy: "/rechnung/destroy"
        }, {onLoaded: onDataLoaded});

        const {
            grid, saveGridInstance, disablePaging, disableColumnChooser, disableState, allowEditing, refreshBtn
        } = useGrid(dataSource);

        onMounted(() => {
            disablePaging();
            disableColumnChooser();
            disableState();
            allowEditing();
            grid.instance.option("editing")!["mode"] = "form";

            grid.instance.on({
                "editorPreparing": onEditorPreparing
            });

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    project_id: props.projectId
                };
            });
        });
        onBeforeUnmount(() => {
            dataSource.store().off("loading");
        });

        const onEditorPreparing = (e: {
            parentType: string, dataField: string, component: DataGrid,
            editorOptions: any, row: any, editorName: string
        }) => {
            if (e.parentType !== "dataRow") {
                return;
            } else if (e.dataField === "memo") {
                e.editorName = "dxTextArea";
                e.editorOptions.autoResizeEnabled = true;
            }
        };

        const columns: Array<Column> = [
            defaultColumn({
                dataField: "nummer",
                dataType: "number",
                editorOptions: {
                    format: "#" // only decimal
                },
                validationRules: [{
                    type: "required"
                }, {
                    type: "numeric"
                }, {
                    type: "stringLength",
                    min: 6,
                    max: 6
                }, uniqueValidator(["nummer"], "/rechnung/validatenummer")
                ]
            }),
            dateColumn({
                dataField: "datum",
                validationRules: [{
                    type: "required"
                }]
            }),
            currencyColumn({
                config: {
                    dataField: "netto"
                },
                required: true,
                min: 0,
                max: 99999999.99
            }),
            currencyColumn({
                config: {
                    dataField: "brutto"
                },
                required: true,
                min: 0,
                max: 99999999.99
            }),
            hourColumn({

                dataField: "stunden_verrechnet",
                caption: "verrechnete Stunden",
                validationRules: [{
                    type: "required"
                }, {
                    type: "numeric"

                }, {
                    type: "range",
                    min: 0,
                    max: 9999999.99
                }]
            }),
            dateColumn({
                dataField: "bezahlt_am",
                editorOptions: {
                    showClearButton: true
                }
            }),
            defaultColumn({
                dataField: "memo",
                //minWidth: 180
            }),
            placeHolderColumn,
            {
                type: "buttons",
showInColumnChooser: false,
                alignment: "right",
                buttons: [
                    actionDownload("Rechnung herunterladen"),
                    actionDelete(),
                    {name: "edit"},
                    {name: "delete"}
                ]
            }
        ];

        const toolbar = {
            items: [
                toolbarSaveButton,
                toolbarRevertButton,
                refreshBtn()
            ]
        } as Toolbar;

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns,
            toolbar
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
