<script setup lang="ts">
import {useRegisterSW} from "virtual:pwa-register/vue"
import DxButton from "devextreme-vue/button";

const {
    needRefresh,
    updateServiceWorker
} = useRegisterSW({
    onRegistered(r) {
        r && setInterval(async () => {
            console.log("Checking for sw update")
            await r.update()
        }, 60000 /* 60sec */)
    }
})
</script>

<template>
  <div
    v-if="needRefresh"
    class="pwa-toast"
  >
    Eine neue Portalversion ist verfügbar!
    Bitte speichern sie ihre Änderungen und laden sie die Seite neu.
    <DxButton
      text="Neu laden"
      type="default"
      @click="updateServiceWorker()"
    /> <a
      href="/changelog.html"
      target="_blank"
    ><DxButton
      text="Changelog"
    /></a>
  </div>
</template>

<style lang="scss" scoped>
@import "../themes/vars.scss";

.pwa-toast {
  position: fixed;
  left: 0;
  top: 0;
  margin: 0;
  padding: 11px;
  z-index: 5000;
  text-align: center;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: $state1;
  width: 100%;
  min-height: 46px;

  a {
    background-color: black;
  }
}
</style>
