<template>
  <div>
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      :selection="{mode: 'multiple', selectAllMode:'page', deferred:false}"
      @initialized="saveGridInstance"
    />
    <DxToolbar
      render-as="bottomToolbar"
      :items="toolbarBottomItems"
    />
  </div>
</template>
<script lang="ts">
import {boolColumn, dateColumn, placeHolderColumn} from "@/utils/columns";
import {handleErrors} from "@/utils/errors";
import {fixPopupGridLayout, hasPermission, useGrid} from "@/utils/grid";
import {createCustomPopup, downloadFile} from "@/utils/helpers";
import {fetchBackend, gridDataSource} from "@/utils/network";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {DxToolbar} from "devextreme-vue/toolbar";
import {LoadOptions} from "devextreme/data";
import {Properties as dxButtonOptions} from "devextreme/ui/button"
import {Column} from "devextreme/ui/data_grid";
import {Item as dxToolbarItem} from "devextreme/ui/toolbar"
import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";

interface Day {
    id: number,
    tag: string,
    isready: boolean,
    verrechnet: boolean
}

export default defineComponent({
    components: {
        DxDataGrid,
        DxToolbar
    },
    props: {
        projectId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {

        // preselect rows
        const onDataLoaded = (result: any) => {
            const preselect = (result as { data: Array<Day> }).data.filter(day => day.isready && !day.verrechnet).map(day => day.id);
            grid.instance.selectRows(preselect, false);
            fixPopupGridLayout(grid.instance);
        };

        const dataSource = gridDataSource({
            read: "/projekt/getdays"
        }, {onLoaded: onDataLoaded});

        const {
            grid, saveGridInstance, disablePaging, disableColumnChooser, disableState
        } = useGrid(dataSource);

        onMounted(() => {
            disablePaging();
            disableColumnChooser();
            disableState();
            grid.instance.option("remoteOperations", false); // local sort
            grid.instance.option("width", "100%");

            grid.instance.on("selectionChanged", onSelectionChanged);

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    project_id: props.projectId
                };
            });
        });
        onBeforeUnmount(() => {
            dataSource.store().off("loading");
        });

        const columns: Array<Column> = [
            {
                caption: "#",
                cellTemplate: function (cellElement, cellInfo) {
                    cellElement.textContent = (cellInfo.rowIndex! + 1).toString();
                },
                width: 35,
                alignment: "right",
                allowEditing: false,
                allowFiltering: false
            },
            dateColumn({dataField: "tag", caption: "Datum"}),
            boolColumn({dataField: "isready", caption: "abgeschlossen", showEditorAlways: false}),
            boolColumn({dataField: "verrechnet", caption: "verrechnet", showEditorAlways: false}),
            placeHolderColumn
        ];

        const toolbarBottomItems = ref([{
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "Vorschau",
                icon: "message",
                disabled: true,
                onClick: () => {
                    createXlsFromIds(grid.instance.getSelectedRowKeys());
                }
            } as dxButtonOptions
        }, {
            widget: "dxButton",
            location: "after",
            locateInMenu: "auto",
            options: {
                text: "Rechnung entgültig erstellen",
                icon: "message",
                disabled: true,
                onClick: () => {
                    // loading button
                    toolbarBottomItems.value[1].options = {
                        ...toolbarBottomItems.value[1].options,
                        disabled: true,
                        text: "Erstelle Rechnung..."
                    };

                    // create bill
                    fetchBackend("/projekt/bill", {
                        method: "POST",
                        body: new URLSearchParams({
                            projectDay_ids: JSON.stringify(grid.instance.getSelectedRowKeys()),
                            project_id: (props.projectId as number).toString()
                        })
                    })
                    .then(handleErrors)
                    .then((result) => {
                        //start creating xls in new window
                        createXlsFromIds(grid.instance.getSelectedRowKeys());

                        // reset loading button
                        toolbarBottomItems.value[1].options = {
                            ...toolbarBottomItems.value[1].options,
                            disabled: false,
                            text: "Rechnung entgültig erstellen..."
                        };

                        // close this popup first?
                        //console.log(context.parent!.$parent.instance.hide());

                        // open bill window
                        createCustomPopup({
                            propsData: {
                                childComponent: "BillGrid",
                                childProps: {projectId: props.projectId, billId: result.bill},
                                popupTitle: "Rechnungen",
                                popupWidth: 800,
                                popupHeight: 400,
                                popupHidden: () => {
                                    grid.instance.refresh();
                                }
                            }
                        });
                    });
                }
            } as dxButtonOptions
        }] as Array<dxToolbarItem>);

        const createXlsFromIds = (ids: Array<number>) => {
            downloadFile(`/rechnung/xls/days/${ids.join(",")}`);
        };

        const onSelectionChanged = (e: { selectedRowsData: Array<Day> }) => {
            const all_isready = e.selectedRowsData.every((r) => {
                return r.isready;
            });

            // enable/disable Preview button based on selection count
            // replace button options (replacing single prop does not trigger reactivness)
            toolbarBottomItems.value[0].options = {
                ...toolbarBottomItems.value[0].options,
                disabled: !e.selectedRowsData.length
            };

            /*  disable saveBtn if
             * nothing is selected
             * one selected is not r.get('isready')
             * no permission
            */
            toolbarBottomItems.value[1].options = {
                ...toolbarBottomItems.value[1].options,
                disabled: !e.selectedRowsData.length || !all_isready || !hasPermission(["ADM", "GFA", "BOF"])
            };
        };

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns,
            toolbarBottomItems
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
