<template>
  <div :class="`${projectId == null ? 'content-block' : ''} dx-card`">
    <dx-data-grid
      :class="`grid-container ${projectId == null ? 'stretch-grid' : ''}`"
      :columns="columns"
      :data-source="dataSource"
      :toolbar="toolbar"
      @initialized="saveGridInstance"
    >
      <dx-state-storing storage-key="customer-missiondetail" />
      <template #headline>
        <h1>{{ $route.meta.title }}</h1>
      </template>
      <template #stateCellColor="{ data }">
        <state-svg :state-id="data.value" />
      </template>
      <template #stateSelectBoxItem="{ data }">
        <div>
          <state-svg :state-id="data && data.id" />
          {{ data && data.name }}
        </div>
      </template>
    </dx-data-grid>
  </div>
</template>
<script lang="ts">
import StateSvg from "@/components/state-svg.vue";
import {
    currencyColumn,
    dateColumn,
    defaultColumn,
    hourColumn,
    placeHolderColumn,
    stateColumn,
    timeColumn
} from "@/utils/columns";
import {fixPopupGridLayout, useGrid} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid, DxStateStoring} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import {Column, Toolbar} from "devextreme/ui/data_grid";
import {defineComponent, onMounted} from "vue";

export default defineComponent({
    components: {
        DxDataGrid,
        DxStateStoring,
        StateSvg
    },
    props: {
        projectId: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {
        const dataSource = gridDataSource({
            read: "/einsatz/customerindex"
        }, {
            onLoaded: () => {
                if (props.projectId) {
                    fixPopupGridLayout(grid.instance);
                }
            }
        });

        const {
            grid, saveGridInstance, disableColumnChooser, allowExporting, refreshBtn, allowFiltering
        } = useGrid(dataSource);
        grid.noYear = false;

        onMounted(() => {
            disableColumnChooser();
            allowExporting();
            allowFiltering();
            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    ...(props.projectId != null && {project_id: props.projectId})
                };
            });
        });

        const toolbar = {
            items: [
                ...props.projectId == null ? [{
                    location: "before",
                    template: "headline",
                    locateInMenu: "never"
                }] : [],
                "exportButton",
                refreshBtn()
            ]
        } as Toolbar;

        const columns: Array<Column> = [
            stateColumn(),
            dateColumn({
                dataField: "tag"
            }),
            defaultColumn({
                dataField: "projekt",
            }),
            defaultColumn({
                dataField: "personal_name",
                caption: "Name"
            }),
            defaultColumn({
                dataField: "tarif_label",
                caption: "Tätigkeit"
            }),
            timeColumn({
                dataField: "checkin",
                caption: "von"
            }),
            timeColumn({
                dataField: "checkout",
                caption: "bis"
            }),
            hourColumn({
                dataField: "anfahrt"
            }),
            currencyColumn({
                config: {
                    dataField: "km_geld_kunde",
                    caption: "km"
                }
            }),
            defaultColumn({
                caption: "Crewleitung",
                calculateCellValue: function (rowData: any) {
                    if (rowData.crewleader) {
                        return rowData.mobil;
                    } else {
                        return "";
                    }
                }
            }),
            placeHolderColumn
        ];

        // return data visible to template
        return {
            grid, // used by beforeRouteLeave
            saveGridInstance,
            dataSource,
            columns,
            toolbar
        };
    }
});
</script>

<style lang="scss" scoped>

</style>
