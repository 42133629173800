<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <dx-button
          icon="menu"
          styling-mode="text"
          @click="toggleMenuFunc"
        />
      </dx-item>

      <dx-item
        location="before"
      >
        <div>
          <img
            :src="logo"
            alt="Logo"
          >
        </div>
      </dx-item>

      <!--      <dx-item-->
      <!--        location="before"-->
      <!--        css-class="header-title dx-toolbar-label"-->
      <!--      >-->
      <!--        <div>-->
      <!--          {{ title }}-->
      <!--        </div>-->
      <!--      </dx-item>-->

      <dx-item
        v-if="!hasPermission(['PER', 'CUS'])"
        location="after"
        locate-in-menu="auto"
      >
        <drop-down
          name="firm"
          placeholder="Firma"
        />
      </dx-item>

      <dx-item
        v-if="!hasPermission(['PER'])"
        location="after"
        locate-in-menu="auto"
      >
        <drop-down
          name="year"
          placeholder="Jahr"
        />
      </dx-item>

      <dx-item
        v-if="!hasPermission(['PER', 'CUS'])"
        location="after"
        locate-in-menu="auto"
      >
        <drop-down
          name="region"
          placeholder="Region"
        />
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <dx-button
          class="user-button authorization"
          width="100%"
          height="27"
          styling-mode="text"
        >
          <user-panel
            :menu-items="userMenuItems"
            menu-mode="context"
          />
        </dx-button>
      </dx-item>

      <template #menuUserItem="{}">
        <user-panel
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import DropDown from "./drop-down.vue"
import auth from "../auth";
import {confirm} from "devextreme/ui/dialog";
//import {subscribe} from "@/utils/media-query";
import UserPanel from "./user-panel.vue";
import {usePiniaStore} from "@/store";
import {hasPermission} from "@/utils/grid";

export default {
    components: {
        DxButton,
        DxToolbar,
        DxItem,
        UserPanel,
        DropDown
    },

    props: {
        menuToggleEnabled: Boolean,
        /* eslint-disable vue/require-default-prop */
        toggleMenuFunc: Function,
        /* eslint-enable vue/require-default-prop */
    },
    data() {
        return {
            store: usePiniaStore(),
            userMenuItems: [
                {
                    text: "Zugangsdaten/2FA ändern",
                    icon: "key",
                    onClick: () => {
                        this.$router.push({
                            path: "/profile"
                        });
                    }
                },
                {
                    text: "Alle Einstellungen zurücksetzen",
                    icon: "clear",
                    onClick: () => {
                        const result = confirm("Spalten, Abstände, Sortierung, etc. ALLER Ansichten werden zurückgesetzt!", "Achtung");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                localStorage.clear();
                                window.location.reload();
                            }
                        });
                    }
                },
                {
                    text: "Logout",
                    icon: "fas fa-sign-out-alt",
                    onClick: this.onLogoutClick
                }
            ]
        };
    },
    computed: {
        logo() {
            return this.store.config.logo;
        },
        title()  {
            return this.store.config.title;
        }
    },

    methods: {
        onLogoutClick() {
            auth.logOut().then(() => {
                this.$router.push({
                    path: "/login-form",
                    query: {redirect: this.$route.path}
                });
            });
        },
        hasPermission
    }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../themes/vars.scss";
@import "../themes/dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: var(--header-height);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: 60px; /* $side-panel-min-width; */
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 0;
  }
}
</style>
