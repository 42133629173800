import auth from "@/auth";
import {usePiniaStore} from "@/store";
import {
    createWebHistory,
    createWebHashHistory,
    createRouter, RouteRecordRaw
} from "vue-router";
import {nextTick} from "vue";

const Login = () => import("./views/login-form.vue");
const Profile = () => import("./views/profile-view.vue");
const Home = () => import("./views/home-view.vue");
const Personal = () => import("./views/personal-view.vue");
const Customer = () => import("./views/customer-view.vue");
const Project = () => import("./views/project-view.vue");
const Tarif = () => import("./views/tarif-view.vue");
const TarifGroup = () => import("./views/tarif-group.vue");
const Material = () => import("./views/material-view.vue");
const MaterialGroup = () => import("./views/material-group.vue");
const Upload = () => import("./views/upload-view.vue");
const BillOverview = () => import("./views/bill-overview.vue");
const MissionOverview = () => import("./views/mission-overview.vue");
const MaterialOverview = () => import("./views/material-overview.vue");
const NoobList = () => import("./views/noob-list.vue");
const Brutto = () => import("./views/brutto-view.vue");
const WorkingTimeRecord = () => import("./views/working-time-record.vue");
const Contract = () => import("./views/contract-view.vue");
const Calendar = () => import("./views/calendar-view.vue");
const Mission = () => import("./views/mission-view.vue");
const CallList = () => import("./views/call-list.vue");
const MissionReportOverview = () => import("./views/mission-report-overview.vue");
const MissionReportOverviewJWE = () => import("./views/mission-report-overview-jwe.vue");
const MissionReportCrewlead = () => import("./views/mission-report-crewlead-view.vue");
const GkkFixwerte = () => import("./views/gkk-fixwerte.vue");
const GkkPersonalOverview = () => import("./views/gkk-personal-overview.vue");
const GkkRegisterList = () => import("./views/gkk-register-list.vue");
const GkkBruttoExport = () => import("./views/gkk-bruttoexport.vue");
const History = () => import("./views/history-view.vue");
const Settings = () => import("./views/settings-view.vue");
const CustomerProfile = () => import("./views/customer/profile-view.vue");
const CustomerProject = () => import("./views/customer/project-view.vue");
const CustomerMission = () => import("./components/customer/mission-grid.vue");
const CustomerBillOverview = () => import("./views/customer/bill-overview.vue");
const CustomerChecklistOverview = () => import("./views/customer/checklist-overview.vue");
const CustomerChecklist = () => import("./views/customer/checklist-view.vue");
// const Test = () =>
//     import(/* webpackChunkName: "test" */ "./views/test.vue");

const routes: Array<RouteRecordRaw> = [
    {
        path: "/home",
        name: "home",
        meta: {requiresAuth: true, layout: "defaultLayout"},
        component: Home
    },
    {
        path: "/personal",
        name: "personal",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Personaldaten"},
        component: Personal
    },
    {
        path: "/kunde",
        name: "kunde",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Kundendaten"},
        component: Customer
    },
    {
        path: "/projekt",
        name: "projekt",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Projektdaten"},
        component: Project
    },
    {
        path: "/tarif",
        name: "tarif",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Tarife"},
        component: Tarif
    },
    {
        path: "/gruppentarif",
        name: "gruppentarif",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Gruppentarife"},
        component: TarifGroup
    },
    {
        path: "/material",
        name: "material",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Material"},
        component: Material
    },
    {
        path: "/materialgruppen",
        name: "materialgroup",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Materialgruppen"},
        component: MaterialGroup
    },
    {
        path: "/upload",
        name: "upload",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Upload"},
        component: Upload
    },
    {
        path: "/rechnung",
        name: "rechnung",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Rechnungen"},
        component: BillOverview
    },
    {
        path: "/einsatz",
        name: "einsatz",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Einsätze"},
        component: MissionOverview
    },
    {
        path: "/materialuebersicht",
        name: "materialuebersicht",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Materialübersicht"},
        component: MaterialOverview
    },
    {
        path: "/noobliste",
        name: "noobliste",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Noob Liste"},
        component: NoobList
    },
    {
        path: "/brutto",
        name: "brutto",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Brutto"},
        component: Brutto
    },
    {
        path: "/arbeitszeitaufzeichnung",
        name: "arbeitszeitaufzeichnung",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Arbeitszeitaufzeichnung"},
        component: WorkingTimeRecord
    },
    {
        path: "/arbeitszeiten",
        name: "arbeitszeiten",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Arbeitszeiten"},
        component: WorkingTimeRecord,
        props: {readOnly: true}
    },
    {
        path: "/dienstvertrag/backoffice",
        name: "dienstvertrag-backoffice",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Dienstverträge"},
        component: Contract

    },
    {
        path: "/dienstvertrag",
        name: "dienstvertrag",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Dienstverträge"},
        component: Contract,
        props: {restricted: true}
    },
    {
        path: "/einteilung",
        name: "einteilung",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Einteilung"},
        component: Calendar
    },
    {
        path: "/einteilung/einsatz/:projectdayId",
        name: "einteilung-einsatz",
        meta: {requiresAuth: true, layout: "defaultLayout", noTitle: true}, // title gets set by component
        component: Mission,
        props: true
    },
    {
        path: "/anrufliste",
        name: "anrufliste",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Anrufliste"},
        component: CallList
    },
    {
        path: "/einsatz/report",
        name: "einsatz-report-uebersicht",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Offene Einsätze"},
        component: MissionReportOverview
    },
    {
        path: "/einsatz/report/:jwe",
        name: "einsatz-report-uebersicht-jwe",
        meta: {requiresAuth: false, layout: "simpleLayout", title: "Mein Einsatz"},
        component: MissionReportOverviewJWE,
        props: true
    },
    {
        path: "/einsatz/report/:projectdayId/crewleitung",
        name: "einsatz-report-crewleitung",
        meta: {requiresAuth: true, layout: "defaultLayout", noTitle: true}, // title gets set by component
        component: MissionReportCrewlead,
        props: true
    },
    {
        path: "/gkk/fixwerte",
        name: "gkkfixwerte",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "GKK Fixwerte"},
        component: GkkFixwerte
    },
    {
        path: "/gkk/uebersicht",
        name: "gkkpersonaluebersicht",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "GKK Personalübersicht"},
        component: GkkPersonalOverview
    },
    {
        path: "/gkk/anmeldung",
        name: "gkkanmeldeliste",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "GKK Anmeldeliste"},
        component: GkkRegisterList
    },
    {
        path: "/gkk/bruttolohnexport",
        name: "bruttolohnexport",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Bruttolohn Export"},
        component: GkkBruttoExport
    },
    {
        path: "/historie/:historyModel",
        name: "historie",
        meta: {requiresAuth: true, layout: "defaultLayout", noTitle: true}, // title gets set by component
        component: History,
        props: true
    },
    {
        path: "/settings",
        name: "settings",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Einstellungen"},
        component: Settings
    },
    {
        path: "/login-form",
        name: "login-form",
        meta: {requiresAuth: false, layout: "simpleLayout", title: "Login"},
        component: Login
    },
    {
        path: "/profile",
        name: "profile",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Profil"},
        component: Profile
    },
    // {
    //     path: "/test",
    //     name: "test",
    //     meta: {requiresAuth: true, layout: "fullscreenLayout"},
    //     component: Test
    // },
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: "/recovery",
        redirect: "/home"
    }
    // {
    //     path: "*",
    //     redirect: "/home"
    // },
    //{ path: '/:pathMatch(.*)*', redirect: "/home" },
];

const routesCustomer: Array<RouteRecordRaw> = [
    {
        path: "/kunde/profil",
        name: "kunde-profil",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Profil"},
        component: CustomerProfile
    },
    {
        path: "/kunde/projekt",
        name: "kunde-projekt",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Projekte"},
        component: CustomerProject
    },
    {
        path: "/kunde/einsatz",
        name: "kunde-einsatz",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Einsätze"},
        component: CustomerMission
    },
    {
        path: "/kunde/rechnung",
        name: "kunde-rechnung",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Rechnungen"},
        component: CustomerBillOverview
    },
    {
        path: "/kunde/checkliste",
        name: "kunde-checkliste-uebersicht",
        meta: {requiresAuth: true, layout: "defaultLayout", title: "Offene Checklisten"},
        component: CustomerChecklistOverview
    },
    {
        path: "/kunde/checkliste/:projectdayId",
        name: "kunde-checkliste",
        meta: {requiresAuth: true, layout: "defaultLayout", noTitle: true}, // title gets set by component
        component: CustomerChecklist,
        props: true
    },
];

const router = createRouter({
    history: import.meta.env.MODE === "development" ? createWebHashHistory() : createWebHistory(),
    routes: [...routes, ...routesCustomer]
});

router.beforeEach(async (to, from, next) => {
    if (to.name === "login-form" && (await auth.authenticated())) {
        next({name: "home"});
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!(await auth.authenticated())) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        if (!to.meta.noTitle) {
            const store = usePiniaStore();
            if (to.meta.title) {
                document.title = to.meta.title + " | " + store.config.title;
            } else {
                document.title = store.config.title;
            }
        }
    });
});

export default router;
