<template>
  <div>
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      @initialized="saveGridInstance"
    />
  </div>
</template>
<script lang="ts">
import {usePiniaStore} from "@/store";
import {currencyColumn, defaultColumn, placeHolderColumn} from "@/utils/columns";
import {fixPopupGridLayout, useGrid} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import {Column} from "devextreme/ui/data_grid";
import {defineComponent, onMounted} from "vue";

export default defineComponent({
    components: {
        DxDataGrid
    },
    props: {
        month: {
            type: Number,
            default:
                null
        }
    },
    setup: (props) => {

        const dataSource = gridDataSource({
            read: "/brutto/calcmonth"
        }, {
            onLoaded: () => {
                fixPopupGridLayout(grid.instance);
            }
        });

        const store = usePiniaStore();

        const {
            grid, saveGridInstance, disablePaging, disableColumnChooser,
            disableState
        } = useGrid(dataSource);

        onMounted(() => {
            disablePaging();
            disableColumnChooser();
            disableState();

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    year: store.filter.year || "",
                    month: props.month,
                    firm: store.filter.firm || ""
                };
            });

        });

        const columns: Array<Column> = [
            defaultColumn({
                dataField: "name"
            }),
            currencyColumn({
                config: {
                    dataField: "before",
                    caption: "brutto alt"
                }
            }),
            currencyColumn({
                config: {
                    dataField: "after",
                    caption: "brutto neu"
                }
            }),
            placeHolderColumn
        ];

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
