<template>
  <div>
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      :column-hiding-enabled="false"
      :selection="{ mode: 'single', deferred:false}"
      :toolbar="toolbar"
      :paging="{enabled: false}"
      :pager="{visible: false}"
      @initialized="saveGridInstance"
    >
      <template #merge>
        <DxButton
          :text="mergeButtonText"
          icon="fas fa-object-group"
          styling-mode="outlined"
          type="normal"
          :disabled="!!mergeDisabled"
          @click="mergeClick()"
        />
      </template>
    </dx-data-grid>
    <DxLoadPanel
      :visible="showLoadingIndicator"
    />
  </div>
</template>
<script lang="ts">
import {dateColumn, defaultColumn, placeHolderColumn} from "@/utils/columns";
import {handleErrors} from "@/utils/errors";
import {useGrid} from "@/utils/grid";
import {fetchBackend} from "@/utils/network";
import {Project} from "@/views/project-view.vue";
import {DxButton} from "devextreme-vue/button";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {DxLoadPanel} from "devextreme-vue/load-panel";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import {Column, Toolbar} from "devextreme/ui/data_grid";
import Popup from "devextreme/ui/popup"
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    components: {
        DxDataGrid,
        DxButton,
        DxLoadPanel
    },
    props: {
        projects: {
            type: Array,
            default: null
        },
        popupInstance: {
            type: Object,
            default: null
        }
    },
    setup: (props) => {
        const showLoadingIndicator = ref(false);
        const mergeDisabled = ref(true);
        const mergeButtonText = ref("Projekte mergen");

        const dataSource = new DataSource({
            store: new ArrayStore({
                data: props.projects as Array<Project>,
                key: "id"
            })
        });

        const {
            grid, saveGridInstance, disableColumnChooser, disableState
        } = useGrid(dataSource);

        onMounted(() => {
            // disablePaging(); seems not to work here, so uncommented and defined in template
            disableColumnChooser();
            disableState();
            grid.instance.on("selectionChanged", onSelectionChanged);
        });

        const columns: Array<Column> = [
            defaultColumn({
                dataField: "nummer",
                dataType: "number",
                width: 65
            }),
            defaultColumn({
                dataField: "kurz",
                caption: "Kunde",
                minWidth: 70
            }),
            defaultColumn({
                dataField: "name"
            }),
            dateColumn({
                dataField: "start",
                width: 80
            }),
            dateColumn({
                dataField: "ende",
                width: 80
            }),
            placeHolderColumn
        ];

        const toolbar = {
            items: [{
                location: "after",
                template: "merge",
                locateInMenu: "auto"
            }]
        } as Toolbar;

        const onSelectionChanged = (e: { selectedRowKeys: Array<number>, selectedRowsData: Array<Project> }) => {
            mergeDisabled.value = e.selectedRowKeys.length == 0;
            if (e.selectedRowsData.length) {
                mergeButtonText.value = `Nr. ${e.selectedRowsData[0].nummer} als Ziel definieren`;
            } else {
                mergeButtonText.value = "Projekte mergen";
            }
        };

        const mergeClick = () => {
            showLoadingIndicator.value = true;
            return fetchBackend("/projekt/merge", {
                method: "POST",
                body: new URLSearchParams({
                    project_target_id: JSON.stringify(grid.instance.getSelectedRowKeys()[0]),
                    project_ids: JSON.stringify((props.projects as Array<Project>).map(p => p.id))
                })
            })
            .then(handleErrors)
            .then(() => {
                showLoadingIndicator.value = false;
                (props.popupInstance as Popup).hide();
            });
        };

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns,
            toolbar,
            showLoadingIndicator,
            mergeDisabled, mergeClick, mergeButtonText
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
