import {handleErrors, handleMinimalErrors} from "@/utils/errors";
import {lookupState} from "@/utils/grid";
import {downloadFile} from "@/utils/helpers";
import {fetchBackend, regionStore} from "@/utils/network";
import {Column, ColumnButton} from "devextreme/ui/data_grid";
import {confirm} from "devextreme/ui/dialog";
import {Properties as dxLookupOptions} from "devextreme/ui/lookup"
import {AsyncRule, ValidationRule} from "devextreme/common"
import {Format, formatDate} from "devextreme/localization";

export const currencyFormat:Format = {
    type: "currency",
    precision: 2
};

export const hourFormat = "#,##0.## h";


// for grids with columnAutoWidth set:
// If the widget's overall content is narrower than the widget's width, the columns are stretched to
// fit the widget. To avoid this, set the columns.width option to "auto".
// see https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/#columnAutoWidth
export const defaultColumn = (config: Column): Column => {
    return {
        width: "auto",
        ...config
    }
};

// should be the last column on every grid with columnAutoWidth, this expands the grid to full available width
export const placeHolderColumn: Column = {
    dataField: "",
    caption: "",
    editCellTemplate: " ", // make editor "invisible" in popup form or row edit
    allowEditing: false,
    allowResizing: false,
    allowReordering: false,
    showInColumnChooser: false
};

// placeholder adapts if other columns are resized (without making a horizontal scrollbar)
export const placeHolderColumnForColumnHidingFalse: Column = {
    ...placeHolderColumn,
    width: "100%"
};

export const currencyColumn = ({
                                   config,
                                   required = true,
                                   min = 0,
                                   max = 0
                               }: { config?: Column, required?: boolean, min?: number, max?: number }): Column => {

    const validationRules: any = [{
        type: "numeric"
    }, ...(required ? [{
        type: "required"
    }] : []), ...(min || max ? [{
        type: "range",
        min: min,
        max: max
    }] : [])];

    return defaultColumn({
        dataType: "number",
        alignment: "right",
        width: 90,
        format: currencyFormat,
        editorOptions: {
            format: "#,##0.## €",
            showClearButton: !required // allow value to clear on not required, or format will always reset to value=0
        },
        validationRules: validationRules,
        ...config
    });
};
export const dateColumn = (config: Column): Column => {
    return defaultColumn({
        dataType: "date",
        format: "dd.MM.yyyy",
        alignment: "center",
        editorOptions: {
            type: "date",
            displayFormat: "dd.MM.yyyy",
            useMaskBehavior: true
        },
        width: 130,
        ...config
    });
};

export const hourColumn = (config: Column): Column => {
    return {
        dataType: "number",
        alignment: "right",
        format: hourFormat,
        width: 80,
        ...config
    }
};

export const timeColumn = (config: Column): Column => {
    return defaultColumn({
        dataType: "datetime",
        format: "shortTime",
        editorOptions: {
            type: "time",
            showDropDownButton:false,
            displayFormat: "shortTime",
            useMaskBehavior: true,
            onKeyDown: (e: any) => {
                // allow reset of timefield, block browser back on backspace
                if (e.event.keyCode == 8 || e.event.keyCode == 46) {
                    e.component.option("value", null);
                    e.event.preventDefault();
                }
            }
        },
        calculateFilterExpression(filterValue, selectedFilterOperation) {
            return [this.dataField, selectedFilterOperation || '=', formatDate(filterValue, "shortTime")];
        },
        width: 90,
        ...config
    });
};

export const boolColumn = (config: Column): Column => {
    return defaultColumn({
        dataType: "boolean",
        width: 65,
        falseText: " ",
        trueText: "x",
        ...config
    });
};

export const ratingColumn = ({config, required = false}: { config?: Column, required?: boolean }): Column => {

    let validationRules: Array<any> = [{
        type: "numeric"
    }];
    if (required) {
        validationRules = [...validationRules, ...[{type: "required"}]]
    }

    return defaultColumn({
        dataField: "bewertung",
        dataType: "number",
        alignment: "center",
        width: 80,
        validationRules: validationRules,
        editorOptions: {
            showClearButton: true, //allow zero to clear from editor
            format: "#0", // only decimal, zero allowed.
            min: 0,
            max: 9
        },
        ...(config ? config : {})
    });

};

export const regionColumn = (config?: Column): Column => {
    return defaultColumn({
        dataField: "region_id",
        caption: "Region",
        validationRules: [{
            type: "required"
        }],
        lookup: {
            dataSource: regionStore,
            valueExpr: "id",
            displayExpr: "label"
        },
        width: "auto",
        ...config
    });
};

export const sexColumn = (config?: Column): Column => {
    return defaultColumn({
        dataField: "geschlecht",
        validationRules: [{
            type: "required"
        }],
        lookup: {
            dataSource: [
                {id: "m", label: "m"},
                {id: "f", label: "w"}
            ],
            valueExpr: "id",
            displayExpr: "label"
        },
        width: 80,
        alignment: "center",
        ...config
    });
};

export const mailCellTemplate = (cellElement: HTMLElement, cellInfo: { value:string }):void => {
    if (cellInfo.value) {
        cellElement.innerHTML = `<a href="mailto:${cellInfo.value}">${cellInfo.value}</a>`;
    }
};

export const phoneCellTemplate = (cellElement: HTMLElement, cellInfo: { value:string }):void => {
    if (cellInfo.value) {
        // remove all whitespace from phone number for nsoftphone
        cellElement.innerHTML = `<a href="tel:${cellInfo.value.replace(/\s/g,'')}">${cellInfo.value}</a>`;
    }
};

export const webCellTemplate = (cellElement: HTMLElement, cellInfo: { value:string }):void => {
    if (cellInfo.value) {
        if (cellInfo.value.indexOf("http") == 0) { //this should be a link
            cellElement.innerHTML = `<a target="_blank" href="${cellInfo.value}">${cellInfo.value}</a>`;
        } else { //this should be a link without http(s):// prefix
            cellElement.innerHTML = `<a target="_blank" href="https://${cellInfo.value}">${cellInfo.value}</a>`;
        }
    }
};

export const mailColumn = (config: Column): Column => {
    // add backend validator
    const validationRules: ValidationRule[] = [
        {type: "email"},
        backendValidator("/index/validateemail")
    ];
    // append configured validation rules from config object
    if (config.validationRules) {
        validationRules.push(...config.validationRules);
        delete config.validationRules;
    }

    return defaultColumn({
        caption: "E-mail",
        dataType: "string",
        validationRules: validationRules,
        cellTemplate: mailCellTemplate,
        ...config
    });
};

export const webColumn = (config: Column): Column => {
    return defaultColumn({
        dataType: "string",
        cellTemplate: webCellTemplate,
        ...config
    });
};

export const phoneColumn = (config: Column, validate = true): Column => {
    // add backend validator
    const validationRules: ValidationRule[] = validate ? [backendValidator("/index/validatephonenumber")] : [];
    // append configured validation rules from config object
    if (config.validationRules) {
        validationRules.push(...config.validationRules);
        delete config.validationRules;
    }

    return defaultColumn({
        dataType: "string",
        cellTemplate: phoneCellTemplate,
        validationRules: validationRules,
        ...config
    });
};

export const stateColumn = (config?: Column): Column => {
    return defaultColumn({
        dataField: "status",
        renderAsync: true,  // defer rendering of complex cells
        cellTemplate: "stateCellColor",
        alignment: "center",
        lookup: lookupState,
        editorOptions: {
            itemTemplate: "stateSelectBoxItem",
            dropDownOptions: {
                minWidth: 110
            }
        } as dxLookupOptions,
        width: 50,
        ...config
    });
};


interface ProbeProfi {
    probe: boolean,
    profi: boolean
}

interface SstAng {
    selbstständig: boolean,
    angestellt: boolean
}

export const checkProbe = (newData: ProbeProfi, value: boolean, currentRowData: ProbeProfi):void => {
    if (currentRowData.probe != value) newData["probe"] = value;
    if (newData["probe"] && currentRowData["profi"]) {
        newData["profi"] = false;
    }
};
export const checkProfi = (newData: ProbeProfi, value: boolean, currentRowData: ProbeProfi):void => {
    if (currentRowData.profi != value) newData["profi"] = value;
    if (newData["profi"] && currentRowData["probe"]) {
        newData["probe"] = false;
    }

};
export const checkSst = (newData: SstAng, value: boolean, currentRowData: SstAng):void => {
    newData["selbstständig"] = value;
    if (newData["selbstständig"] && currentRowData["angestellt"]) {
        newData["angestellt"] = false;
    }
};
export const checkAng = (newData: SstAng, value: boolean, currentRowData: SstAng):void => {
    newData["angestellt"] = value;
    if (newData["angestellt"] && currentRowData["selbstständig"]) {
        newData["selbstständig"] = false;
    }
};

export const actionDownload = (hint = "Datei herunterladen", icon = "download"):ColumnButton => {
    return {
        hint: hint,
        icon: icon,
        visible: (e: any) => {
            return e.row.data.scan;
        },
        onClick: (e: any) => {
            downloadFile(`/index/download/id/${e.row.data.scan}`);
        }
    }
};

export const actionDelete = ():ColumnButton => {
    return {
        hint: "Scan löschen",
        icon: "clear",
        visible: (e: any) => {
            return e.row.data.scan;
        },
        onClick: (e: any) => {
            const scan = e.row.data.scan;
            const result = confirm("Scan " + scan + " löschen?", "Achtung");
            result.then((dialogResult) => {
                if (dialogResult) {
                    fetchBackend("/index/deletescan?nr=" + scan).then(handleErrors).then((result) => {
                        if (result.success) {
                            e.component.refresh();
                        }
                    });
                }
            });
        }
    }
};

export const uniqueValidator = (keys: Array<string>, endpoint: string): AsyncRule => {
    return {
        type: "async",
        ignoreEmptyValue: true,
        validationCallback: (options) => {
            const params:any = keys.reduce((obj, key) => ({
                ...obj,
                [key]: key in options.data ? options.data[key].toString() : "0"
            }), {})
            params["id"] = options.data.id ? options.data.id.toString() : "0";

            return fetchBackend(endpoint, {
                method: "POST",
                body: new URLSearchParams(params)
            }).then(handleMinimalErrors)
            .then(res => {
                // see https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxValidator/Validation_Rules/AsyncRule/#validationCallback
                // ===== if "res" is { isValid: Boolean, message: String } =====
                // simply use resolve(res);
                return Promise.resolve(res);
            })

        }
    }
};

export const backendValidator = (endpoint: string): AsyncRule => {
    return {
        type: "async",
        ignoreEmptyValue: true,
        validationCallback: (options) => {
            const params = {
                value: options.value.toString()
            };

            return fetchBackend(endpoint, {
                method: "POST",
                body: new URLSearchParams(params)
            }).then(handleMinimalErrors)
            .then(res => {
                // see https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxValidator/Validation_Rules/AsyncRule/#validationCallback
                // ===== if "res" is { isValid: Boolean, message: String } =====
                // simply use resolve(res);
                return Promise.resolve(res);
            })
        }
    }
};
