import auth from "@/auth";
import router from "@/router";
import {alert} from "devextreme/ui/dialog";

const catchHttpErrors = (response:Response) => {
    if (response.status == 401) {
        alert("", "Nicht eingelogged!")
        .then(() => {
            auth.logOut().then(() => {
                router.push({
                    path: "/login-form",
                    query: {redirect: router.currentRoute.value.path}
                });
            });
        });
        // abort promise chain
        return Promise.reject("you are logged out");
    } else if (response.status == 403) {
        alert("", "Nicht erlaubt!");
        return Promise.reject("no permission");
    } else {
        response.text().then(text => {
            alert(`${response.status} ${response.statusText}, ${text}!`, "Error");
            // abort promise chain
            return Promise.reject("server error");
        });
    }

    return {}
};

export const handleErrors = (response: Response): any => {

    // catch http status errors
    if (!response.ok) {
        return catchHttpErrors(response);
    }

    // http 200
    else {
        // return json by default
        return response.json().then(json => {
            if (json.message || !json.success) {
                const title: string = json.success ? "Information" : "Fehler";
                let message = "Ein unerwarteter Fehler ist aufgetreten! Bitte den Systemadministrator informieren.";

                if (Array.isArray(json.message)) {
                    message = json.message.join("<br>");
                } else {
                    message = json.message || json.msg || message;
                }

                // return Promise, so alert popop is shown first and any content popup is showned after user confirms
                return alert(message, title).then(()=>{
                    if (json.redirect) {
                        router.push(json.redirect);
                    }
                    return json
                });
            } else {
                return json;
            }

        });
    }
};

// handle errors on simple json results without success or message flags
export const handleMinimalErrors = (response: Response): any => {

    // catch http status errors
    if (!response.ok) {
        return catchHttpErrors(response);
    }

    // http 200
    else {
        // return json by default
        return response.json()
    }
};
