import {usePiniaStore} from "@/store";
import {handleErrors} from "@/utils/errors";
import {loadConfig, switchTheme} from "@/utils/helpers";
import {fetchBackend} from "./utils/network";
import * as Sentry from "@sentry/browser";

let authenticated = true;

const initUser = (id:number, role:string, name:string) => {
    const store = usePiniaStore();

    // set user and role to vuex store
    store.user = {
        id: id,
        role: role,
        name: name,
    };

    Sentry.setUser({
        id: (store.user.id || store.user.name!).toString(),
        username: store.user.name!.toString(),
        role: store.user.role
    });

    // disabled as it does not work with vite in build mode ATM
    // if(store.user.role === "PER") {
    //     switchTheme("generic.light");
    // } else {
    //     switchTheme("generic.light.compact");
    // }
};

export default {
    async authenticated(): Promise<boolean | undefined> {

        const store = usePiniaStore();

        // reload roles if they are unset in vuex store, usually happens on page refresh
        // should be async to wait for backend response
        if (authenticated && !store.user.role) {

            // reload config first
            await loadConfig();

            const response = await fetchBackend("/index/role");
            if (response.status != 200) {
                // eslint-disable-next-line require-atomic-updates
                authenticated = false;
                return authenticated;
            }
            const result = await response.json();
            if (result.success) {
                initUser(result.roles[0].personal_id, result.roles[0].id, result.roles[0].value);
                // eslint-disable-next-line require-atomic-updates
                authenticated = true;
                return authenticated;
            }

        } else {
            return authenticated;
        }


    },
    logIn(login: string, password: string, totp: string): Promise<boolean> {
        return fetchBackend("/user/login", {
            method: 'POST',
            body: new URLSearchParams({
                loginUsername: login,
                loginPassword: password,
                loginTotp: totp
            })
        })
            .then(handleErrors)
            .then((result) => {
                if (result.success) {
                    authenticated = true;
                    initUser(result.roles[0].personal_id, result.roles[0].id, result.roles[0].value);
                    return true;
                } else {
                    return false;
                }
            });
    },
    logOut(): Promise<void> {
        if (authenticated) {
            return fetchBackend("/user/logout")
                .then(() => {
                    authenticated = false;
                    const store = usePiniaStore();
                    store.user = {
                        id: null,
                        role: null,
                        name: null
                    };
                    Sentry.configureScope(scope => scope.setUser(null));
                });
        } else {
            return Promise.resolve();
        }
    }
};
