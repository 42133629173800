import {defineStore} from "pinia"

type Filter = "region" | "firm" | "year";
type FromFilter = { [k in Filter]: number | null };

export interface State {
    filter: FromFilter;
    user: {
        id: number | null;
        role: string | null;
        name: string | null;
    };
    config: {
        version: string | null;
        logo: string | undefined;
        title: string;
    };
}

const state: State = {
    filter: {
        region: null,
        firm: null,
        year: null
    },
    user: {
        id: null,
        role: null,
        name: null
    },
    config: {
        version: null,
        logo: undefined,
        title: "Portal"
    }
};

export const usePiniaStore = defineStore("vec", {
    state: () => (state),
    getters: {},
    actions: {
        setFilterValue(dropdown: Filter, value: number | null) {
            this.filter[dropdown as Filter] = value;
        }
    }
})
