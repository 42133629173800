<template>
  <div>
    <DxForm
      id="form"
      :col-count="2"
      :form-data="data"
      label-location="top"
    >
      <DxItem
        :items="groupedItems.crewleader"
        item-type="group"
        caption="Crewleiter"
      />
      <DxItem
        :items="groupedItems.crew"
        item-type="group"
        caption="Crew"
      />
    </DxForm>
  </div>
</template>
<script lang="ts">
import {DxForm, DxItem} from "devextreme-vue/form";
import {DxTextArea} from "devextreme-vue/text-area";
import {defineComponent} from "vue";

export default defineComponent({
    components: {
        DxForm,
        DxItem,
        DxTextArea // eslint-disable-line vue/no-unused-components
    },
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    setup: () => {

        const editorOptions = (height: number) => {
            return {
                width: 300,
                height: height,
                onFocusIn: (e: { event: any }) => {
                    e.event.target.select();
                }
            }
        };

        const groupedItems = {
            crewleader: [
                {
                    label: {text: "Crewleiter Nr"},
                    editorType: "dxTextArea",
                    dataField: "crewleader_number",
                    editorOptions: editorOptions(100)
                },
                {
                    label: {text: "Crewleiter Text"},
                    editorType: "dxTextArea",
                    dataField: "crewleader_text",
                    editorOptions: editorOptions(200)
                }
            ],
            crew: [
                {
                    label: {text: "Crew Nr"},
                    editorType: "dxTextArea",
                    dataField: "crew_numbers",
                    editorOptions: editorOptions(100)
                },
                {
                    label: {text: "Crew Text"},
                    editorType: "dxTextArea",
                    dataField: "crew_text",
                    editorOptions: editorOptions(200)
                }
            ]
        };

        return {groupedItems};
    }
});


</script>

<style lang="scss" scoped>

</style>
