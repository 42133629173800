<template>
  <div class="dx-card">
    <dx-data-grid
      class="grid-container"
      :columns="columns"
      :data-source="dataSource"
      @initialized="saveGridInstance"
    />
  </div>
</template>
<script lang="ts">
import {actionDelete, actionDownload} from "@/utils/columns";
import {fixPopupGridLayout, hasPermission, useGrid} from "@/utils/grid";
import {gridDataSource} from "@/utils/network";
import {DxDataGrid} from "devextreme-vue/data-grid";
import {LoadOptions} from "devextreme/data";
import {Column} from "devextreme/ui/data_grid";
import {defineComponent, onBeforeUnmount, onMounted} from "vue";

export default defineComponent({
    components: {
        DxDataGrid
    },
    props: {
        projectNr: {
            type: String,
            default: null
        }
    },
    setup: (props) => {
        const dataSource = gridDataSource({
            read: "/projekt/checklist"
        }, {
            onLoaded: () => {
                fixPopupGridLayout(grid.instance);
            }
        });

        const {
            grid, saveGridInstance, disablePaging, disableColumnChooser, disableState
        } = useGrid(dataSource);

        onMounted(() => {
            disablePaging();
            disableColumnChooser();
            disableState();

            dataSource.store().on("loading", (loadOptions: LoadOptions) => {
                loadOptions["userData"] = {
                    ...loadOptions["userData"],
                    project_nr: props.projectNr
                };
            });
        });
        onBeforeUnmount(() => {
            dataSource.store().off("loading");
        });


        const columns: Array<Column> = [
            {
                dataField: "id",
                caption: "Name"
            },
            {
                type: "buttons",
showInColumnChooser: false,
                buttons: [
                    actionDownload("Checkliste herunterladen"),
                    hasPermission(["CUS"]) ? {} : actionDelete()
                ]
            }
        ];

        return {
            grid,
            saveGridInstance,
            dataSource,
            columns
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
