<template>
  <!--div class="content-block"-->
  <footer class="footer">
    <a
      target="_blank"
      href="/changelog.html"
    >v{{ version }}</a> by <a href="https://www.viennaeventcrew.at/">Vienna Event Crew</a>
  </footer>
  <!--/div-->
</template>

<script>

import {usePiniaStore} from "@/store";

export default {
    computed: {
        version() {
            const store = usePiniaStore();
            return store.config.version;
        }
    }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  //border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}
</style>
